// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qSetReportTableContainer table{
    border-collapse: collapse;
}

.qSetReportTableContainer thead tr {
  height: 40px;
}

.qSetReportTableContainer thead th {
  padding: 5px;
  color: #828282;
}

.qSetReportTableContainer thead {
  background-color: #f2f2f2;
}

.qSetReportThCourse {
  width: 10%;
}

.qSetReportThSubject {
  width: 12%;
}

.qSetReportThTopic {
  width: 16%;
}

.qSetReportTableContainer tbody tr {
  height: 30px;
  border-bottom: 1px solid #e0e0e0;
}

.qSetReportTableContainer tbody tr td {
  border-bottom: 1px solid #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/QSetReport/QSetReportComponents/TableQSetReport/tableQSetReport.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".qSetReportTableContainer table{\n    border-collapse: collapse;\n}\n\n.qSetReportTableContainer thead tr {\n  height: 40px;\n}\n\n.qSetReportTableContainer thead th {\n  padding: 5px;\n  color: #828282;\n}\n\n.qSetReportTableContainer thead {\n  background-color: #f2f2f2;\n}\n\n.qSetReportThCourse {\n  width: 10%;\n}\n\n.qSetReportThSubject {\n  width: 12%;\n}\n\n.qSetReportThTopic {\n  width: 16%;\n}\n\n.qSetReportTableContainer tbody tr {\n  height: 30px;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.qSetReportTableContainer tbody tr td {\n  border-bottom: 1px solid #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
