import { toast } from "react-toastify";

export function error(err){
    if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Error");
        console.log(err);
      }
}