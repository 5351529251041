import React from "react";
import Dropdown from "../../../../SubComponents/Dropdown/Dropdown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pdf from "@mikecousins/react-pdf";
import "./translateOptions.css";

function TranslateOptions({
  handelChangeFile,
  fileInfo,
  fileDetails,
  PDFLoadFail,
  state,
  formData,
  handleChange,
  handleAddQuestion,
  fileTypeDrop,
  toAccept,
  handleChangeFileType,
  questionCreate,
}) {
  return (
    <div>
      <form>
        <div className="translateContainer">
          <h1 className="translateH3">
            <ArrowForwardIosIcon className="translateH3Arrow" />
            Translate
          </h1>
        </div>
        <div className="searchTranslateContainer">
          <h4 className="searchTranslateHeading">Translation Question</h4>
          <div className="fileUploadTrans">
            <label className="dropdownLabelTrans" name="File Type">
              File Type
            </label>
            <select
              className="dropdownSelectTrans"
              onChange={(e) => handleChangeFileType(e, "fileType")}
            >
              <option>Select</option>
              {fileTypeDrop.map((options, keyFileType) => {
                return (
                  <option key={keyFileType} value={options.display_name}>
                    {options.display_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="fileUploadTrans">
            <label className="dropdownLabelTrans">
              {/* <TextSnippetOutlinedIcon className="uploadTransPDFIcon" /> */}
              Question File
            </label>
            <input
              type="file"
              name="question_file_path"
              id="question_file_path"
              className={
                fileInfo.fileType === ""
                  ? "fileChooseTransNot"
                  : "fileChooseTrans"
              }
              onChange={(e) => handelChangeFile(e, "file")}
              disabled={fileInfo.fileType === "" ? true : false}
              accept={toAccept}
            ></input>
          </div>
          {fileInfo.fileType !== "" ? (
            <div>
              {fileInfo.fileType === "PDF" ? (
                <div className="thumbnailContainerTrans">
                  {fileInfo.fullDetails !== "" ? (
                    <Pdf
                      file={fileDetails}
                      page={1}
                      // onDocumentLoadSuccess={pdfsPage}
                      onDocumentLoadFail={PDFLoadFail}
                    />
                  ) : null}
                </div>
              ) : null}
              {fileInfo.fileType === "Image" ? (
                <div className="thumbnailContainerUpload">
                  {fileInfo.fullDetails !== "" ? (
                    <img
                      src={fileDetails}
                      alt="Thumbnail"
                      className="imgUploadThumbnail"
                    />
                  ) : null}
                </div>
              ) : null}
              {fileInfo.fileType === "Text" ? (
                <div className="thumbnailContainerUpload">
                  {fileInfo.fullDetails !== "" ? (
                    <iframe
                      title="TextFrame"
                      src={fileDetails}
                      className="uploadFileText"
                    ></iframe>
                  ) : null}
                </div>
              ) : null}
              {fileInfo.fileType === "HTML" ? (
                <div className="thumbnailContainerUpload">
                  {fileInfo.fullDetails !== "" ? (
                    <iframe src={fileDetails} title="HTMLFrame"></iframe>
                  ) : null}
                </div>
              ) : null}{" "}
            </div>
          ) : null}

          <div className="fileUploadTrans">
            <label className="dropdownLabelTrans">
              {/* <TextSnippetOutlinedIcon className="uploadTransPDFIcon" /> */}
              Question Source File
            </label>
            <input
              type="file"
              name="source_file_path"
              id="source_file_path"
              disabled={fileInfo.fileType === "" ? true : false}
              className={
                fileInfo.fileType === ""
                  ? "fileChooseTransNot"
                  : "fileChooseTrans"
              }
              onChange={(e) => handelChangeFile(e, "source")}
            ></input>
          </div>
          <Dropdown
            label="Medium"
            options={state.medium}
            name="medium_id"
            displayName="medium_name"
            idName="medium_id"
            value={formData.medium_id}
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Board"
            options={state.board}
            name="board_id"
            displayName="board_display_name"
            idName="board_id"
            value={formData.board_id}
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Course"
            options={state.course}
            name="course_id"
            displayName="display_name"
            idName="course_id"
            value={formData.course_id}
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Subject"
            options={state.subject}
            name="subject_id"
            displayName="display_name"
            idName="subject_id"
            value={formData.subject_id}
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Sub-Subject"
            options={state.subSubject}
            name="sub_subject_id"
            displayName="display_name"
            idName="sub_subject_id"
            value={formData.sub_subject_id}
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
          <Dropdown
            label="Topic"
            options={state.topic}
            name="topic_1_id"
            displayName="display_name"
            idName="topic_id"
            value={formData.topic_1_id}
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
        </div>
        <div className="transButtonContainer">
          <button
            className="transButtonSearch"
            type="button"
            onClick={handleAddQuestion}
          >
            Add Question
          </button>
          {questionCreate ? (
            <div className="loadingDivTrans">
              <p className="loadingTextDataTrans">Question Translated</p>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default TranslateOptions;
