import https from "../init/https";

//QB-RES-01: Set a reservation for Migrate Questions
export async function reserveQue(questionId, userId) {
  const reserve = await https.post(
    `/qb/reservations?qb_question_id=${questionId}&reserved_by_id=${userId}`
  );
  return reserve;
}

//QB-RES-02: Display whom reserved the questions
export async function whoReserved(questionId) {
  const who = await https.get(`/qb/reservations/${questionId}`);
  return who;
}


//QB-RES-03: Clear the Reservation for a given Question
export async function clearReservation(reserveId, questionId) {
  const clear = await https.delete(
    `qb/reservations/?reservation_id=${reserveId}&qb_question_id=${questionId}`
  );
  return clear;
}

//QB-RES-04: Clear all Reservations for a given User
export async function clearAll(userId) {
  const allClear = await https.delete(
    `/qb/reservations/clearall?reserved_by_id=${userId}`
  );
  return allClear;
}
