import React, { useState, useEffect } from "react";
import "./optionsRight.css";

function OptionsRight(props) {
  const [correct, setCorrect] = useState(false);
  const [wrong, setWrong] = useState(false);

  useEffect(() => {
    if (props.value === "Yes") {
      setCorrect(true);
    }

    if (props.value === "No") {
      setWrong(true);
    }
    //eslint-disable-next-line
  },[]);

  function handleCorrect() {
    setCorrect(!correct);
    if (wrong === true) {
      setWrong(false);
    }
  }

  function handleWrong() {
    setWrong(!wrong);
    if (correct === true) {
      setCorrect(false);
    }
  }

  return (
    <div className="compulsoryContainer">
      <h4 className="optionsH4">Options</h4>
      <p>(Select to Mark Correct Answer)</p>
      <div className="optionsContainer">
        <div>
          <label className="checkbox-label">
            <input
              type="checkbox"
              onChange={(e) => {
                props.onChange(props.optionOne, "responseYesNo");
                handleCorrect();
              }}
              checked={correct}
            />
            <span className="mark"></span>
            {props.optionOne}
          </label>
        </div>
        <div>
          <label className="checkbox-labelRight">
            <input
              type="checkbox"
              onChange={(e) => {
                props.onChange(props.optionTwo, "responseYesNo");
                handleWrong();
              }}
              checked={wrong}
            />
            <span className="mark"></span>
            {props.optionTwo}
          </label>
        </div>
      </div>
    </div>
  );
}

export default OptionsRight;
