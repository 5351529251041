// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.startLoadSpinTranslate {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 150px !important;
    margin-left: -100px;
    height: 50px;
    margin-top: -25px;
    z-index: 1;
  }`, "",{"version":3,"sources":["webpack://./src/components/Screens/TranslateQuestions/translateQuestion.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,UAAU;EACZ","sourcesContent":[".startLoadSpinTranslate {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    width: 150px !important;\n    margin-left: -100px;\n    height: 50px;\n    margin-top: -25px;\n    z-index: 1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
