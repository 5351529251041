// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pagination code start */

.paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
  
  .numberContainer {
    display: flex;
  }
  
  .seleNumber {
    background-color: #1b529d;
    color: white;
    margin: 0px 2px 0 10px;
    padding: 7px 15px 6px 14px;
    border-radius: 50%;
    font-family: "Ruda", sans-serif;
    font-size: 15px;
    border: none;
    cursor: pointer;
  }
  
  .notSeleNumber {
    /* background-color: #b6e8ef; */
    margin: 0px 2px 0 10px;
    padding: 7px 15px 6px 14px;
    border-radius: 50%;
    font-family: "Ruda", sans-serif;
    font-size: 15px;
    border: none;
    cursor: pointer;
  }
  
  .notSeleNumberP {
    /* background-color: #b6e8ef; */
    margin: 0px 2px 0 10px;
    padding: 7px 15px 6px 14px;
    border-radius: 50%;
    font-family: "Ruda", sans-serif;
    font-size: 15px;
    border: none;
  }
  
  .paginationArrows {
    border: none;
    background-color: white;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .paginationArrows:disabled{
    cursor: not-allowed;
  }
  
  .peginationNumberDiv{
    display: flex;
  }
  
  /* Pagination code ends */
  `, "",{"version":3,"sources":["webpack://./src/components/SubComponents/Pagination/pagination.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;IAClB,+BAA+B;IAC/B,eAAe;IACf,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,+BAA+B;IAC/B,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;IAClB,+BAA+B;IAC/B,eAAe;IACf,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,+BAA+B;IAC/B,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;IAClB,+BAA+B;IAC/B,eAAe;IACf,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA,yBAAyB","sourcesContent":["/* Pagination code start */\n\n.paginationContainer {\n    display: flex;\n    justify-content: center;\n    margin-top: 25px;\n  }\n  \n  .numberContainer {\n    display: flex;\n  }\n  \n  .seleNumber {\n    background-color: #1b529d;\n    color: white;\n    margin: 0px 2px 0 10px;\n    padding: 7px 15px 6px 14px;\n    border-radius: 50%;\n    font-family: \"Ruda\", sans-serif;\n    font-size: 15px;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .notSeleNumber {\n    /* background-color: #b6e8ef; */\n    margin: 0px 2px 0 10px;\n    padding: 7px 15px 6px 14px;\n    border-radius: 50%;\n    font-family: \"Ruda\", sans-serif;\n    font-size: 15px;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .notSeleNumberP {\n    /* background-color: #b6e8ef; */\n    margin: 0px 2px 0 10px;\n    padding: 7px 15px 6px 14px;\n    border-radius: 50%;\n    font-family: \"Ruda\", sans-serif;\n    font-size: 15px;\n    border: none;\n  }\n  \n  .paginationArrows {\n    border: none;\n    background-color: white;\n    margin-left: 10px;\n    cursor: pointer;\n  }\n  \n  .paginationArrows:disabled{\n    cursor: not-allowed;\n  }\n  \n  .peginationNumberDiv{\n    display: flex;\n  }\n  \n  /* Pagination code ends */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
