// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layoutButton {
  font-size: 20px;
  border: none;
  background-color: #ffd777;
  z-index: 3;
  padding-top: 9px;
  position: fixed;
  cursor: pointer;
}

.layoutContainer {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.togglebar {
  width: 210px;
  float: left;
  margin-right: 35px;

  background-color: black;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  min-height: 700px;
  position: fixed;
  margin-left: -8px;
}

.togglebarClose {
  width: 0;
}

.togg {
  margin-left: 220px;
}

.toggClose {
  margin-left: 0;
}


.layoutP {
  margin: 4px 0;
}

.developer {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/layout.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;;EAElB,uBAAuB;EACvB,+BAA+B;EAC/B,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".layoutButton {\n  font-size: 20px;\n  border: none;\n  background-color: #ffd777;\n  z-index: 3;\n  padding-top: 9px;\n  position: fixed;\n  cursor: pointer;\n}\n\n.layoutContainer {\n  display: flex;\n  justify-content: left;\n  flex-direction: row;\n}\n\n.togglebar {\n  width: 210px;\n  float: left;\n  margin-right: 35px;\n\n  background-color: black;\n  font-family: \"Ruda\", sans-serif;\n  font-size: 13px;\n  min-height: 700px;\n  position: fixed;\n  margin-left: -8px;\n}\n\n.togglebarClose {\n  width: 0;\n}\n\n.togg {\n  margin-left: 220px;\n}\n\n.toggClose {\n  margin-left: 0;\n}\n\n\n.layoutP {\n  margin: 4px 0;\n}\n\n.developer {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
