import React, { useState, useEffect, useRef } from "react";
import "./migrate.css";
import Dropdown from "../../SubComponents/Dropdown/Dropdown";
// import Axios from "axios";
// import { BASE_URL_MIGRATE } from "../../config/Api";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pdf from "@mikecousins/react-pdf";
import "./migrate.css";
// import { BASE_URL } from "../../config/Api";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import qs from "qs";
import Pagination from "../../SubComponents/Pagination/Pagination";
import {
  fetchMiClass,
  fetchMiLan,
  fetchMiQues,
  fetchMiQuesCount,
  fetchMiSubject,
  fetchMiSubSubject,
  fetchMiSUbTopic,
  fetchMiSy,
  fetchMiTopic,
} from "../../../api/ms_sql";
import { uploadQuestionFunction } from "../../../api/questions";
import {
  clearAll,
  clearReservation,
  reserveQue,
  whoReserved,
} from "../../../api/queReservation";
import { REWRITE_MIGRATE_PATH, REWRITE_URLS } from "../../config/Api";

var pageNum = 1;
var language_id = -1;
var syllabus_id = -1;
var class_id = -1;
var subject_id = -1;
var sub_subject_id = 0;
var topic_id = 0;
var sub_topic_id = 0;
var linkSel = [];
var queSel = [];
var link;
var linkArray = [];
var notReservedCount = 0;
// var reservedCount = 0;
var imageFileText = "File not supported";
var errorQuestionId = [];
let reservedArray = [];
// let sellectAllStatus = false;

function Migrate() {
  const [formData, setFormData] = useState({
    language_id: "",
    syllabus_id: "",
    class_id: "",
    subject_id: "",
    subSubject_id: "",
    topic_id: "",
    subTopic_id: "",
  });
  const [state, setState] = useState({
    language: [],
    syllabus: [],
    class: [],
    subject: [],
    subSubject: [],
    topic: [],
    subTopic: [],
  });
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionList, setQuestionList] = useState([]);
  const [selLink, setSelLink] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [migrateLoading, setMigrateLoding] = useState(false);
  const [migrateComplete, setMigrateComplete] = useState(false);
  const [error, setError] = useState(false);
  const [queNum, setQueNum] = useState(0);
  const [noData, setNoData] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(true);
  const initialRender = useRef(true);
  const [selQuestionID, setSelQuestionID] = useState("");
  // console.log(formData);

  console.log(linkSel);
  console.log(notReservedCount);

  useEffect(() => {
    reservedArray = [];
    const fetchData = async () => {
      try {
        // const getLanguage = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetLanguageList`
        // );
        const getLanguage = await fetchMiLan();
        setState({
          ...state,
          language: getLanguage.data.languageList,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  function handleClick(clickedLink, questionID) {
    console.log(clickedLink);
    setSelLink(clickedLink);
    setSelQuestionID(questionID);
  }

  const userId = window.localStorage.getItem("userId");
  // const token = window.localStorage.getItem("token");
  // const Auth = {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // };

  async function handleAddToBacklog() {
    setMigrateComplete(false);
    errorQuestionId = [];
    setError(false);
    if (linkSel.length === 0) {
      toast.error("No Questions Selected");
      setTimeout(() => {
        toast.error("Click On The Checkbox To Select");
      }, 2000);
    }
    if (linkSel.length !== 0) {
      setMigrateLoding(true);
      console.log(migrateLoading);
      for (var i = 0; i < linkSel.length; i++) {
        if (questionList[i]) {
          // setQueNum(i);
          await handleBacklog(i);
          // await handleQueDelete(i);
        }
        if (i === linkSel.length - 1) {
          setMigrateLoding(false);
          setMigrateComplete(true);
        }
      }
      await clearAll(userId);
    }
    if (linkSel === []) {
      toast.error("Please Select Questions To Migrate");
    }
  }

  //function to cancel migrate to backlog request
  function handleCancel() {
    linkSel = [];
    queSel = [];
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    errorQuestionId = [];
    setQueNum(0);
    let tempList = questionList.map((question) => {
      return { ...question, isChecked: false };
    });
    setQuestionList(tempList);
  }

  async function handleSelectAll(e) {
    setStartUpLoad(true);
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    var check = e.target.checked;
    console.log(check);
    if (check === true) {
      linkSel = [];
      queSel = [];
      let newList = [];
      newList = await checkReservationTable(questionList, "selectAll");
      console.log(newList);
      let tempList = newList.map((question) => {
        return question.isReserved !== true
          ? { ...question, isChecked: true }
          : { ...question, isChecked: false };
      });
      for (var i = 0; i < numberOfQuestions; i++) {
        if (newList[i]) {
          if (newList[i].isReserved !== true) {
            linkSel = [...linkSel, newList[i].PhotoPath];
            queSel = [...queSel, newList[i].QuestionID];
            // console.log(newList[i].PhotoPath);
          }
        }
      }
      console.log(tempList);
      setQuestionList(tempList);
      setStartUpLoad(false);
    } else if (check === false) {
      linkSel = [];
      queSel = [];
      try {
        console.log(userId);
        const clear = await clearAll(userId);
        if (clear.status === 200) {
          let tempList = questionList.map((question) => {
            return question.isReserved !== true
              ? { ...question, isChecked: false }
              : { ...question, isChecked: false };
          });
          console.log(tempList);
          setQuestionList(tempList);
          setStartUpLoad(false);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  }

  async function handleBacklog(i) {
    const data = {
      question_file_path: linkSel[i],
      migrate_question_id: queSel[i],
      source_file_path: "",
    };
    console.log(data);
    try {
      // let response = await Axios.post(
      //   `${BASE_URL}/qb/question_group_files?question_format_id=3&user_id=${userId}`,
      //   data,
      //   Auth
      // );
      let response = await uploadQuestionFunction(3, userId, data);
      console.log(response);
      console.log(data);
      setQueNum(i);
      return response;
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        toast.error(err.response.data.err.message);
      } else {
        toast.error("Server Error");
      }
      setError(true);
      console.log(data);
      errorQuestionId.push(queSel[i]);
      linkSel = [];
      queSel = [];
      setMigrateLoding(false);
      setMigrateComplete(false);
      setQueNum(0);
      let tempList = questionList.map((question) => {
        return { ...question, isChecked: false };
      });
      setQuestionList(tempList);
    }
  }

  // async function handleQueDelete(i) {
  //   let data = {
  //     Questions: [
  //       {
  //         QuestionId: queSel[i],
  //         IsSync: true,
  //       },
  //     ],
  //   };

  //   Axios.post(
  //     `${BASE_URL_MIGRATE}/PostQuestionMigrateBulk`,
  //     qs.stringify(data)
  //   )
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.data) {
  //         console.log(err.response);
  //         toast.error(err.response.data.message);
  //       }
  //       migrateLoading(false);
  //     });
  // }

  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    setFormData({ ...formData, [selLabel]: valueSele });
    fetchOtherResults(e, selLabel);
  }

  async function fetchOtherResults(e, selLabel) {
    setStartUpLoad(true);
    if (selLabel === "language_id") {
      language_id = e.target.value;
      syllabus_id = -1;
      class_id = -1;
      subject_id = -1;
      sub_subject_id = 0;
      topic_id = 0;
      sub_topic_id = 0;
      setFormData((prevState) => {
        return {
          ...prevState,
          syllabus_id: -1,
          class_id: -1,
          subject_id: -1,
          subSubject_id: 0,
          topic_id: 0,
          subTopic_id: 0,
        };
      });
      try {
        // const getSyllabus = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetSyllabusList`
        // );
        const getSyllabus = await fetchMiSy();
        setState((prevState) => ({
          ...prevState,
          syllabus: getSyllabus.data.syllabusList,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "syllabus_id") {
      syllabus_id = e.target.value;
      class_id = -1;
      subject_id = -1;
      sub_subject_id = 0;
      topic_id = 0;
      sub_topic_id = 0;
      setFormData((prevState) => {
        return {
          ...prevState,
          class_id: -1,
          subject_id: -1,
          subSubject_id: 0,
          topic_id: 0,
          subTopic_id: 0,
        };
      });
      try {
        // const getClass = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetClassList?languageID=${language_id}&SyllabusID=${syllabus_id}`
        // );
        const getClass = await fetchMiClass(language_id, syllabus_id);
        setState((prevState) => ({
          ...prevState,
          class: getClass.data.Class,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "class_id") {
      class_id = e.target.value;
      subject_id = -1;
      sub_subject_id = 0;
      topic_id = 0;
      sub_topic_id = 0;
      setFormData((prevState) => {
        return {
          ...prevState,
          subject_id: -1,
          subSubject_id: 0,
          topic_id: 0,
          subTopic_id: 0,
        };
      });
      try {
        // const getSubject = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetSubjectList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}`
        // );
        const getSubject = await fetchMiSubject(
          language_id,
          syllabus_id,
          class_id
        );
        setState((prevState) => ({
          ...prevState,
          subject: getSubject.data.subjectList,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subject_id") {
      subject_id = e.target.value;
      sub_subject_id = 0;
      topic_id = 0;
      sub_topic_id = 0;
      setFormData((prevState) => {
        return {
          ...prevState,
          subSubject_id: 0,
          topic_id: 0,
          subTopic_id: 0,
        };
      });
      try {
        // const getSubSubject = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetSubSubjectList?SubjectID=${subject_id}`
        // );
        const getSubSubject = await fetchMiSubSubject(subject_id);
        setState((prevState) => ({
          ...prevState,
          subSubject: getSubSubject.data.subSubjectList,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subSubject_id") {
      sub_subject_id = e.target.value;
      topic_id = 0;
      sub_topic_id = 0;
      setFormData((prevState) => {
        return {
          ...prevState,
          topic_id: 0,
          subTopic_id: 0,
        };
      });
      try {
        // const getTopic = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetTopicList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}`
        // );
        const getTopic = await fetchMiTopic(
          language_id,
          syllabus_id,
          class_id,
          subject_id,
          sub_subject_id
        );
        setState((prevState) => ({
          ...prevState,
          topic: getTopic.data.topicList,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "topic_id") {
      topic_id = e.target.value;
      sub_topic_id = 0;
      setFormData((prevState) => {
        return {
          ...prevState,
          subTopic_id: 0,
        };
      });
      try {
        // const getSubTopic = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetSubTopicList?TopicID=${topic_id}`
        // );
        const getSubTopic = await fetchMiSUbTopic(topic_id);
        console.log("getSubTopic", getSubTopic);
        setState((prevState) => ({
          ...prevState,
          subTopic: getSubTopic.data.subTopicList,
        }));
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subTopic_id") {
      sub_topic_id = e.target.value;
      setStartUpLoad(false);
    }
  }

  async function checkReservationTable(getQuestionList, whichFunction) {
    let tempGetQuestionList = [];
    for (let i = 0; i < getQuestionList.length; i++) {
      try {
        const whoResv = await whoReserved(getQuestionList[i].QuestionID);
        // console.log(whoResv.data);
        if (whoResv.data.data) {
          // sellectAllStatus = true;
          tempGetQuestionList.push({
            ...getQuestionList[i],
            isChecked: false,
            reservedBy: whoResv.data.data.reserved_by_name,
            reservationById: whoResv.data.data.reserved_by_id,
            isReserved: true,
          });
        } else {
          tempGetQuestionList.push(getQuestionList[i]);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setSubmitLoading(false);
        setStartUpLoad(false);
      }
    }
    notReservedCount = 0;
    tempGetQuestionList.map((question) => {
      return (
        question.isReserved !== true &&
        (notReservedCount = notReservedCount + 1)
      );
      // : (reservedCount = reservedCount + 1);
    });
    console.log(tempGetQuestionList);
    if (whichFunction === "selectAll") {
      return tempGetQuestionList;
    } else {
      // if (REWRITE_URLS === 1) {
      // chgImgURLRewrite(getQuestionList.data);
      // } else {
      setQuestionList(tempGetQuestionList);
      setSubmitLoading(false);
      setStartUpLoad(false);
      window.scrollTo(0, 480);
      // }
    }
  }

  // function chgImgURLRewrite(table) {
  //   const tempTable = table;
  //   const changeImageURL = tempTable.map((row) => {
  //     const newPhotoPath = FILE_URL_REWRITE + row.PhotoPath.slice(22);
  //     return { ...row, PhotoPath: newPhotoPath };
  //   });
  //   setQuestionList(changeImageURL);
  //   setSubmitLoading(false);
  //   setStartUpLoad(false);
  //   window.scrollTo(0, 480);
  // }

  //function to search questions
  async function handleSubmit() {
    linkSel = [];
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    setSelLink("");
    setSelQuestionID("");
    errorQuestionId = [];
    notReservedCount = 0;
    linkArray = [];
    link = "";
    if (language_id !== -1) {
      linkArray.push(`languageId=${language_id}`);
    }
    if (syllabus_id !== -1) {
      linkArray.push(`syllabusId=${syllabus_id}`);
    }
    if (class_id !== -1) {
      linkArray.push(`classId=${class_id}`);
    }
    if (subject_id !== -1) {
      linkArray.push(`subjectId=${subject_id}`);
    }
    if (sub_subject_id !== -1) {
      linkArray.push(`subSubjectId=${sub_subject_id}`);
    }
    if (topic_id !== -1) {
      linkArray.push(`topicId=${topic_id}`);
    }
    if (sub_topic_id !== -1) {
      linkArray.push(`subTopicId=${sub_topic_id}`);
    }
    link = linkArray.join("&");
    console.log(link);
    if (subject_id === -1) {
      toast.error("Please Select Options Till SubSubject");
    }
    if (+subject_id !== -1) {
      setSubmitLoading(true);
      setNumberOfQuestions(0);
      setQuestionList([]);
      try {
        // const getNumberOfQuestions = await Axios.get(
        //   `${BASE_URL_MIGRATE}/GetQuestionTotalCount?${link}`
        // );
        const getNumberOfQuestions = await fetchMiQuesCount(link);
        console.log(getNumberOfQuestions.data.QuestionTotalCounts);
        if (getNumberOfQuestions.data.QuestionTotalCounts !== 0) {
          // const getQuestionList = await Axios.get(
          //   `${BASE_URL_MIGRATE}/GetQuestionAllList?${link}&OFFSET=${offSet}&FETCHNEXT=${10}`
          // );
          const getQuestionList = await fetchMiQues(link, offSet);
          setNumberOfQuestions(getNumberOfQuestions.data.QuestionTotalCounts);
          console.log("question", getQuestionList);
          checkReservationTable(getQuestionList.data);
        }
        if (getNumberOfQuestions.data.QuestionTotalCounts === 0) {
          setNoData(true);
          setSubmitLoading(false);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.status);
          toast.error(err.response.data.message);
          setSubmitLoading(false);
        }
        if (err.response.status === 404) {
          setNoData(true);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
          toast.error("Server Error");
        }
      }
    }
  }

  useEffect(() => {
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function handlePagination() {
        linkSel = [];
        queSel = [];
        console.log(offSet);
        if (subject_id !== -1) {
          try {
            link = `LanguageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}&TopicID=${topic_id}&SubtopicID=${sub_topic_id}`;
            // const getQuestionList = await Axios.get(
            //   `${BASE_URL_MIGRATE}/GetQuestionAllList?LanguageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}&TopicID=${topic_id}&SubtopicID=${sub_topic_id}&OFFSET=${offSet}&FETCHNEXT=${10}`
            // );
            const getQuestionList = await fetchMiQues(link, offSet);
            // setQuestionList(getQuestionList.data);
            // setStartUpLoad(false);
            notReservedCount = 0;
            // reservedCount = 0;
            checkReservationTable(getQuestionList.data);
          } catch (err) {
            if (err.response && err.response.data) {
              console.log(err.response);
              toast.error(err.response.data.message);
            } else {
              toast.error("Server Error");
            }
            setStartUpLoad(false);
          }
        }
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  async function handleCheckbox(e, selectedLink, row) {
    setMigrateComplete(false);
    setMigrateLoding(false);
    setError(false);
    setStartUpLoad(true);
    const valueSele = e.target.checked;
    const nameSele = parseInt(e.target.name);
    console.log(valueSele, nameSele);
    if (valueSele === true) {
      try {
        const isReserve = await whoReserved(row.QuestionID);
        console.log(isReserve.data);
        if (isReserve.data.data) {
          toast.error(
            "The question is reserved by " +
              isReserve.data.data.reserved_by_name
          );
          let tempList = questionList.map((question) => {
            if (question.QuestionID === nameSele) {
              return {
                ...question,
                isChecked: false,
                reservedBy: isReserve.data.data.reserved_by_name,
                reservationById: isReserve.data.data.reserved_by_id,
                isReserved: true,
              };
            } else {
              return question;
            }
          });
          console.log(tempList);
          setQuestionList(tempList);
        } else {
          const reserve = await reserveQue(nameSele, userId);
          console.log(reserve);
          linkSel = [...linkSel, selectedLink];
          queSel = [...queSel, nameSele];
          let tempList = questionList.map((question) => {
            if (question.QuestionID === nameSele) {
              return {
                ...question,
                isChecked: true,
                reservationId: reserve.data.data.o_reservation_id,
              };
            } else {
              return question;
            }
          });
          reservedArray.push({
            questionId: nameSele,
            reservationId: reserve.data.data.o_reservation_id,
          });
          console.log(reservedArray);
          window.localStorage.setItem(
            "reservedArray",
            JSON.stringify(reservedArray)
          );
          console.log(tempList);
          setQuestionList(tempList);
        }

        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (valueSele === false) {
      try {
        const clear = await clearReservation(row.reservationId, nameSele);
        console.log(clear);
        let clearIndex = reservedArray.indexOf({
          reservationId: row.reservationId,
        });
        console.log(clearIndex);
        reservedArray.splice(clearIndex, 1);
        window.localStorage.setItem(
          "reservedArray",
          JSON.stringify(reservedArray)
        );
        var index = linkSel.indexOf(selectedLink);
        var indexQue = queSel.indexOf(nameSele);
        linkSel.splice(index, 1);
        queSel.splice(indexQue, 1);
        let tempList = questionList.map((question) => {
          return question.QuestionID === nameSele
            ? { ...question, isChecked: false }
            : question;
        });
        tempList.forEach((question) => {
          question.QuestionID === nameSele && delete question.reservationId;
        });
        console.log(tempList);
        setQuestionList(tempList);
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    console.log(linkSel);
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestions / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div className="queContainer">
        <h3 className="queH3">
          {" "}
          <ArrowForwardIosIcon className="queH3Arrow" />
          Migrate Entry
        </h3>
      </div>
      <div className="compulsoryContainer">
        <h4 className="compulsoryHeading">Migrate Details</h4>
        <Dropdown
          label="Language"
          options={state.language}
          displayName="Name"
          name="language_id"
          value={formData.language_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </Dropdown>
        <Dropdown
          label="Syllabus"
          options={state.syllabus}
          displayName="Name"
          name="syllabus_id"
          value={formData.syllabus_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </Dropdown>
        <Dropdown
          label="Class"
          options={state.class}
          displayName="ClassName"
          name="class_id"
          value={formData.class_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </Dropdown>
        <Dropdown
          label="Subject"
          options={state.subject}
          displayName="Name"
          name="subject_id"
          value={formData.subject_id}
          idName="ID"
          onChange={handleChange}
          req={true}
        >
          {" "}
        </Dropdown>
        <Dropdown
          label="Sub-Subject"
          options={state.subSubject}
          displayName="Name"
          name="subSubject_id"
          value={formData.subSubject_id}
          idName="ID"
          onChange={handleChange}
          req={false}
          displayEngName={"display_name_eng"}
        >
          {" "}
        </Dropdown>
        <Dropdown
          label="Topic"
          options={state.topic}
          displayName="Name"
          name="topic_id"
          value={formData.topic_id}
          idName="ID"
          onChange={handleChange}
          req={false}
          displayEngName={"display_name_eng"}
        />
        <Dropdown
          label="Sub-Topic"
          options={state.subTopic}
          displayName="Name"
          name="subTopic_id"
          value={formData.subTopic_id}
          idName="ID"
          onChange={handleChange}
          req={false}
          displayEngName={"display_name_eng"}
        />
      </div>
      <div className="compulsoryContainer">
        <button
          type="submit"
          className="addSubmit"
          onClick={() => {
            handleSubmit();
            setNoData(false);
          }}
        >
          Search
        </button>
        {submitLoading ? (
          <div className="loadingDiv">
            <ReactLoading
              type="spinningBubbles"
              color="#68dff0"
              className="reactLoadingIcon"
            />
            <h5 className="loadingText">Loading</h5>
          </div>
        ) : null}
        {noData ? (
          <div className="loadingDiv">
            <h5 className="loadingTextData">Data is not available</h5>
          </div>
        ) : null}
      </div>
      <div>
        {questionList !== undefined && numberOfQuestions !== 0 ? (
          <div className="compulsoryContainer">
            <h4 className="compulsoryHeading">{numberOfQuestions} Questions</h4>
            <div className="selectDiv">
              <div className="selectAllDiv">
                <input
                  type="checkbox"
                  onChange={(e) => handleSelectAll(e)}
                  className="selectAllInput"
                  checked={
                    notReservedCount !== 0
                      ? linkSel.length === parseInt(notReservedCount)
                        ? true
                        : false
                      : false
                  }
                  // disabled= {sellectAllStatus}
                ></input>
                <p className="selectAllP">Select All</p>
              </div>
              <div>
                {linkSel.length === 0 ? null : (
                  <p className="selectedP">{linkSel.length} Selected</p>
                )}
              </div>
            </div>
            <div className="tableContainerMigrate">
              <table className="migrateTable">
                <thead>
                  <tr>
                    <th>Sl. No.</th>
                    <th>Select</th>
                    <th>Question #</th>
                    <th className="child2Migrate">Old Question Code</th>
                    <th className="child3Migrate">Access Level</th>
                    <th>Question Type</th>
                    <th>Migrate Status</th>
                    <th>Photo Path</th>
                  </tr>
                </thead>
                <tbody>
                  {questionList.map((row, questionKey) => {
                    return (
                      <tr
                        key={questionKey}
                        className={
                          +selQuestionID === +row.QuestionID ? "selQSet" : null
                        }
                        onClick={(e) =>
                          handleClick(row.PhotoPath, row.QuestionID)
                        }
                      >
                        <td>{questionKey + 1}.</td>
                        <td>
                          {row.IsMigrate === true ? (
                            "Migrated"
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckbox(e, row.PhotoPath, row)
                              }
                              checked={row.isChecked ? row.isChecked : false}
                              className={
                                // row.IsMigrate === true ? "checkboxhid" : null
                                row.isReserved === true ? "checkboxhid" : null
                              }
                              // disabled={row.IsMigrate === true ? true : false}
                              disabled={row.isReserved === true ? true : false}
                              name={row.QuestionID}
                            ></input>
                          )}
                        </td>
                        <td>{row.QuestionID}</td>
                        <td>{row.QuestionNumber}</td>
                        <td>{row.AccessLevel}</td>
                        <td>{row.QuestionType}</td>
                        <td>
                          {/* {row.IsMigrate === true ? "Migrated" : "Not-Migrated"} */}
                          {row.isReserved
                            ? "Reserved by " + row.reservedBy
                            : "Not Reserved"}
                        </td>
                        <td>
                          {row.PhotoPath.slice(-3) === "pdf" ? (
                            <Pdf
                              file={
                                REWRITE_URLS === "1"
                                  ? REWRITE_MIGRATE_PATH +
                                    row.PhotoPath.slice(22)
                                  : row.PhotoPath
                              }
                              page={pageNum}
                            />
                          ) : (
                            imageFileText
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* )} */}
            </div>
            <Pagination
              paginationClick={paginationClick}
              numberOfRows={numberOfQuestions}
              offSet={offSet}
              pageNumber={pageNumber}
            />
            {selLink !== "" ? (
              <div className="migrateImageContainer ">
                {selLink.slice(-3) === "pdf" ? (
                  <Pdf
                    file={selLink}
                    page={pageNum}
                    className="migrateBigImage"
                  />
                ) : (
                  <p>{imageFileText}</p>
                )}
              </div>
            ) : null}
            <div className="migrateAddContainer">
              <button className="migrateButton" onClick={handleAddToBacklog}>
                Add To My Backlogs
              </button>
              <div className="migrateLoading">
                {migrateLoading && error === false ? (
                  <div className="loadingDivMigrateAdd">
                    <button onClick={handleCancel} className="cancelMigrate">
                      Cancel
                    </button>
                    <ReactLoading
                      type="spinningBubbles"
                      color="#68dff0"
                      className="reactLoadingIconAdd"
                    />
                    <h5 className="loadingTextAdd">
                      Migrating {queNum} / {linkSel.length}
                    </h5>
                  </div>
                ) : null}
                {migrateComplete && error === false ? (
                  <div className="loadingDiv">
                    <h5 className="loadingTextMigrate">
                      Migration Complete {linkSel.length}/{linkSel.length}
                    </h5>
                  </div>
                ) : null}
                {error ? (
                  <div className="loadingDiv">
                    <h5 className="loadingTextMigrateError">
                      Error! Please try again.
                    </h5>
                  </div>
                ) : null}
              </div>
            </div>
            {error
              ? errorQuestionId.map((errQueId, errKey) => {
                  return (
                    <p className="errorP" key={errKey}>
                      Question Id - {errQueId}, has invalid PDF structure.
                    </p>
                  );
                })
              : null}
          </div>
        ) : null}
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Migrate;
