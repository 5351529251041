import React, { useState, useEffect } from "react";
import "./translateQuestion.css";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchDataTranslate } from "./TranslateFunctions/fetchDataTranslate";
import { fetchOtherResultsTranslate } from "./TranslateFunctions/fetchOtherResultsTranslate";
import TranslateOptions from "./TranslateComponets/TranslateOptions/TranslateOptions";
import { populateFormData } from "./TranslateFunctions/populateFormData";
import { translateSubmit } from "./TranslateFunctions/translateSubmit";
import { fetchQueFormat } from "../../../api/qbMasterData";
import { error } from "../../SubComponents/Error/error";

let toAccept = "";

function TranslateQuestions() {
  const [formData, setFormData] = useState({
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_1_id: -1,
    difficulty_level: "",
    access_level: -1,
    medium_id: -1,
    question_code: -1,
    from_date: "",
    to_date: "",
    added_by_user_id: -1,
    createdBy: "",
    createdByName: "",
    objective_id: -1,
    taxonomy_id: -1,
    translate: true,
  });
  const [state, setState] = useState({
    board: [],
    medium: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
  });
  const [fileInfo, setFileInfo] = useState({
    name: "",
    fullDetails: "",
    pdfFile: [],
    fileType: "",
    fileTypeId: "",
    nameSource: "",
    fullDetailsSource: "",
  });
  const [fileDetails, setFileDetails] = useState("");
  const [startUpLoad, setStartUpLoad] = useState(false);
  const [fileTypeDrop, setFileTypeDrop] = useState([]);
  const [questionCreate, setQuestionCreate] = useState(false);

  let institution_id = 0;

  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    fetchOtherResultsTranslate(
      e,
      selLabel,
      institution_id,
      setFormData,
      setStartUpLoad,
      state,
      setState,
      formData
    );
    setFormData({ ...formData, [selLabel]: valueSele });
  }

  const handelChangeFile = async (e, tab) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > 10e8) {
        toast.error("File should be less than 100Mb");
      } else {
        if (tab === "file") {
          const objectUrl = URL.createObjectURL(file);
          setFileDetails(objectUrl);
          setFileInfo((prevState) => ({
            ...prevState,
            name: file.name,
            fullDetails: file,
          }));
        } else if (tab === "source") {
          setFileInfo((prevState) => ({
            ...prevState,
            nameSource: file.name,
            fullDetailsSource: file,
          }));
        }
      }
    } else {
      setFileDetails("");
      setFileInfo((prevState) => ({
        ...prevState,
        name: "",
        fullDetails: "",
      }));
    }
  };

  // function pdfsPage(pdfdata) {
  //   var numPages = pdfdata._pdfInfo.numPages;
  //   // setNumOfPages(numPages);
  // }

  function PDFLoadFail() {
    toast.error("This is an in-valid PDF");
    setFileInfo({ ...fileInfo, fullDetails: "" });
  }

  useEffect(() => {
    setStartUpLoad(true);
    async function fetchDropQueType() {
      try {
        const getFileType = await fetchQueFormat();
        setFileTypeDrop(getFileType.data.data);
      } catch (err) {
        error(err);
        setStartUpLoad(false);
      }
    }
    fetchDropQueType();
    fetchDataTranslate(setState, state, setStartUpLoad);
    populateFormData(setFormData, setStartUpLoad, setState, institution_id);

    // eslint-disable-next-line
  }, []);

  async function handleAddQuestion() {
    if (fileInfo.fullDetails === "") {
      toast.error("File not selected, Please choose file");
    }
    if (fileInfo.fullDetailsSource === "") {
      toast.error("Choose the Source File");
    } else {
      await translateSubmit(
        formData,
        setStartUpLoad,
        fileInfo,
        setQuestionCreate
      );
    }
  }

  function handleChangeFileType(e) {
    const valueSele = e.target.value;
    const idSele = e.target.selectedIndex;

    setFileInfo((prevState) => ({
      ...prevState,
      fileType: valueSele,
      fileTypeId: idSele,
    }));
  }
  if (fileInfo.fileType === "Text") {
    toAccept = ".txt";
  }
  if (fileInfo.fileType === "PDF") {
    toAccept = ".pdf";
  }
  if (fileInfo.fileType === "Image") {
    toAccept = ".png, .jpg, .jpeg, .webp, .jfif";
  }
  if (fileInfo.fileType === "HTML") {
    toAccept = ".html, .htm";
  }
  if (fileInfo.fileType === "PPT") {
    toAccept = ".ppt, .pptx";
  }

  console.log(formData);

  return (
    <div>
      {startUpLoad ? (
        <div>
          <ReactLoading
            type="spin"
            color="#68dff0"
            className="startLoadSpinTranslate"
          />
        </div>
      ) : null}
      <TranslateOptions
        handelChangeFile={handelChangeFile}
        fileInfo={fileInfo}
        fileDetails={fileDetails}
        PDFLoadFail={PDFLoadFail}
        state={state}
        formData={formData}
        handleChange={handleChange}
        handleAddQuestion={handleAddQuestion}
        fileTypeDrop={fileTypeDrop}
        toAccept={toAccept}
        handleChangeFileType={handleChangeFileType}
        questionCreate={questionCreate}
      />
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default TranslateQuestions;
