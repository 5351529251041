import Axios from "axios";
import { BASE_URL_MIGRATE } from "../components/config/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const token = localStorage.getItem("token");

//The below line is to make sure that you can use two interceptors in the same project
//Without this the API base URL will be taken only from one of the interceptor 
const axios = Axios.create();
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    config.baseURL = BASE_URL_MIGRATE;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log("error", error.message);
    toast.error(error.message);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data) {
        console.log("error", error.response.data.message);
        console.log(error)
        // toast.error(error.response.data.message)
    }
    return Promise.reject(error);
}
);


const httpsMsSql = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch
};

export default httpsMsSql;
