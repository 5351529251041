import React, { useState, useEffect } from "react";
import "./options.css";


function Options(props) {
  const [correct, setCorrect] = useState(false);
  const [nearCorrect, setNearCorrect] = useState(false);

  function handleCheck() {
    setCorrect(!correct);
    if (nearCorrect === true) {
      setNearCorrect(false);
    }
  }

  function handleCheckNear() {
    setNearCorrect(!nearCorrect);
    if (correct === true) {
      setCorrect(false);
    }
  }

  useEffect(() => {
    if (props.arrayCrt.includes(props.alphabet) === true) {
      setCorrect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.arrayCrt)]);

  useEffect(() => {
    if (props.arrayNr.includes(props.alphabet) === true) {
      setNearCorrect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.arrayNr)]);

  return (
    <div>
      <div className="optionsContainer">
        <div>
          <h4 className="optionsSerial">{props.alphabet}</h4>
        </div>
        <div>
          <label className="checkbox-label">
            <input
              type="checkbox"
              onChange={(e) => {
                props.onChange(e, "correct", "responseMCQ", props.alphabet);
                handleCheck();
              }}
              checked={correct}
            />
            <span className="mark"></span>
            Correct
          </label>
        </div>
        <div>
          <label className="checkbox-label1">
            <input
              type="checkbox"
              onChange={(e) => {
                props.onChange(e, "nearCorrect", "responseMCQ", props.alphabet);
                handleCheckNear();
              }}
              checked={nearCorrect}
            />
            <span className="mark1"></span>
            Near Correct
          </label>
        </div>
      </div>
    </div>
  );
}

export default Options;
