import {
  fetchCourse,
  fetchSubject,
  fetchTopic,
} from "../../../../api/institution";
import { boardList } from "../../../../api/boards";
import { fetchObjective } from "../../../../api/objective";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function fetchOtherResultsTopicReport(
  e,
  selLabel,
  institution_id,
  setFormData,
  setStartUpLoad,
  state,
  setState,
  formData,
) {
  if (selLabel === "question_type_id") {
    setFormData((prevState) => {
      return {
        ...prevState,
        board_id: -1,
        course_id: -1,
        subject_id: -1,
        topic_id: -1,
      };
    });
    setStartUpLoad(true);
    try {
      const getBoard = await boardList();
      setState({
        ...state,
        board: getBoard.data.data,
      });
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === "board_id") {
    setFormData((prevState) => {
      return {
        ...prevState,
        course_id: -1,
        subject_id: -1,
        topic_id: -1,
      };
    });
    setStartUpLoad(true);
    const board_id = e.target.value;
    try {
      const getCourse = await fetchCourse(institution_id, board_id);
      setState({
        ...state,
        course: getCourse.data.data,
      });
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === "course_id") {
    setFormData((prevState) => {
      return {
        ...prevState,
        subject_id: -1,
        topic_id: -1,
      };
    });
    setStartUpLoad(true);
    const course_id = e.target.value;
    try {
      const getSubject = await fetchSubject(
        institution_id,
        formData.board_id,
        course_id
      );
      setState({
        ...state,
        subject: getSubject.data.data,
      });
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === "subject_id") {
    setFormData((prevState) => {
      return { ...prevState, topic_id: -1 };
    });
    setStartUpLoad(true);
    const subject_id = e.target.value;
    try {
      const getTopic = await fetchTopic(
        institution_id,
        formData.board_id,
        formData.course_id,
        subject_id,
        -1
      );
      setState((prevState) => ({
        ...prevState,
        topic: getTopic.data.data,
      }));
      setStartUpLoad(false);
    } catch (err) {
      console.log(err, "error while fetching data");
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }
  if (selLabel === "taxonomy_id") {
    setStartUpLoad(true);
    const taxonomy_id = e.target.value;
    console.log(taxonomy_id);
    try {
      const getObjective = await fetchObjective(taxonomy_id);
      setState({
        ...state,
        objectives: getObjective.data.data,
      });
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }
}
