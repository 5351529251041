import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Dropdown from "../../../../SubComponents/Dropdown/Dropdown";
import ReactLoading from "react-loading";
import "./qSetReportSearch.css";
import { qSetReportSubmit } from "../../QSetReportFunction/qSetReportSubmit";

function QSetReportSearch({
  state,
  formData,
  handleChange,
  submitLoading,
  noData,
  setSubmitLoading,
  setNoData,
  setNumberOfQSetReports,
  setQSetReportList,
  limit,
  offSet,
  setStartUpLoad,
  createDownloadInfo,
}) {
  return (
    <div>
      <form>
        <div className="qSetReportContainer">
          <h1 className="qSetReportH3">
            <ArrowForwardIosIcon className="qSetReportArrow" />
            QSet Report
          </h1>
        </div>
        <div>
          <div className="qSetReportSearchContainer">
            <h4 className="qSetReportHeading">Search QSet Report</h4>
            <Dropdown
              label="Medium"
              options={state.medium}
              name="medium_id"
              displayName="medium_name"
              idName="medium_id"
              value={formData.medium_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Questions Type"
              options={state.questionsType}
              displayName="display_name"
              name="question_type_id"
              idName="id"
              value={formData.question_type_id}
              onChange={handleChange}
              req={false}
            >
              {" "}
            </Dropdown>
            <Dropdown
              label="Board"
              options={state.board}
              name="board_id"
              displayName="board_display_name"
              idName="board_id"
              value={formData.board_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Course"
              options={state.course}
              name="course_id"
              displayName="display_name"
              idName="course_id"
              value={formData.course_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Subject"
              options={state.subject}
              name="subject_id"
              displayName="display_name"
              idName="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              req={false}
            />
            <Dropdown
              label="Sub-Subject"
              options={state.subSubject}
              name="sub_subject_id"
              displayName="display_name"
              idName="sub_subject_id"
              value={formData.sub_subject_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <Dropdown
              label="Topic"
              options={state.topic}
              name="topic_id"
              displayName="display_name"
              idName="topic_id"
              value={formData.topic_id}
              onChange={handleChange}
              req={false}
              displayEngName={"display_name_eng"}
            />
            <div className="qSetReportDropdownDiv">
              <label className="qSetReportLabel">From Date</label>
              <input
                className="qSetReportSelect"
                type="date"
                value={formData.from_date}
                onChange={(e) => handleChange(e, "from_date")}
              ></input>
            </div>
            <div className="qSetReportDropdownDiv">
              <label className="qSetReportLabel">To Date</label>
              <input
                className="qSetReportSelect"
                type="date"
                value={formData.to_date}
                onChange={(e) => handleChange(e, "to_date")}
              ></input>
            </div>
          </div>
        </div>
        <div className="qSetReportSearchButtonContainer">
          <button
            className="qSetReportSearchBtn"
            id="searchQuestions"
            type="button"
            onClick={() => {
              qSetReportSubmit(
                setSubmitLoading,
                setNoData,
                formData,
                setNumberOfQSetReports,
                setQSetReportList,
                limit,
                offSet,
                setStartUpLoad
              );
              createDownloadInfo(formData);
            }}
          >
            Search
          </button>
          {submitLoading ? (
            <div className="qSetReportloadingDiv">
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="qSetRepReactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {noData ? (
            <div className="qSetReportloadingDiv">
              <h5 className="qSetRepLoadingTextData">Data is not available</h5>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default QSetReportSearch;
