// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  min-height: 60px;
  padding: 0 15px;
  border-bottom: 1px solid #c9aa5f;
  background: #ffd777;
  z-index: 1;
  font-family: "Ruda", sans-serif;
  padding-left: 28px;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.navbarLogo {
  color: #ffffff;
  margin-left: 15px;
  text-transform: uppercase;
  text-decoration: none;
}
.navbarH1 {
  font-size: 22px;
  font-family: "Ruda", sans-serif;
  margin-bottom: 2px;
}

.navbarh2 {
  font-size: 12px;
  font-family: "Ruda", sans-serif;
}

.navbarSubContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.navbarLogout {
  color: #f2f2f2;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 8px 15px;
  margin-right: 15px;
  background: #68dff0;
  margin-top: 15px;
  cursor: pointer;
}
.navbarLogout:hover {
  background-color: #51cac2;
}

.navbarLogout:active {
  border: 1px solid #03afa9 !important;
}

.togglebar {
  width: 250px;
}

.togglebar1 {
  width: 0;
}

`, "",{"version":3,"sources":["webpack://./src/components/Header/navbar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,QAAQ;EACR,MAAM;EACN,gBAAgB;EAChB,eAAe;EACf,gCAAgC;EAChC,mBAAmB;EACnB,UAAU;EACV,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,QAAQ;AACV","sourcesContent":[".navbar {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  min-height: 60px;\n  padding: 0 15px;\n  border-bottom: 1px solid #c9aa5f;\n  background: #ffd777;\n  z-index: 1;\n  font-family: \"Ruda\", sans-serif;\n  padding-left: 28px;\n}\n\n.navbarContainer {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n}\n\n.navbarLogo {\n  color: #ffffff;\n  margin-left: 15px;\n  text-transform: uppercase;\n  text-decoration: none;\n}\n.navbarH1 {\n  font-size: 22px;\n  font-family: \"Ruda\", sans-serif;\n  margin-bottom: 2px;\n}\n\n.navbarh2 {\n  font-size: 12px;\n  font-family: \"Ruda\", sans-serif;\n}\n\n.navbarSubContainer {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n}\n\n.navbarLogout {\n  color: #f2f2f2;\n  font-size: 14px;\n  border-radius: 4px;\n  border: none;\n  padding: 8px 15px;\n  margin-right: 15px;\n  background: #68dff0;\n  margin-top: 15px;\n  cursor: pointer;\n}\n.navbarLogout:hover {\n  background-color: #51cac2;\n}\n\n.navbarLogout:active {\n  border: 1px solid #03afa9 !important;\n}\n\n.togglebar {\n  width: 250px;\n}\n\n.togglebar1 {\n  width: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
