import React, { useState, useEffect, useRef } from "react";
import Dropdown from "../../SubComponents/Dropdown/Dropdown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { BASE_URL } from "../../config/Api";
// import Axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import {
  TopicTable,
  SubTopicTable,
  DifficultyLevel,
  Objective,
  Marks,
} from "../../SubComponents/SummaryTable/SummaryTable";
import "./qSetView.css";
import Pagination from "../../SubComponents/Pagination/Pagination";
import PublicIcon from "@mui/icons-material/Public";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import SearchSuggestion from "../../SubComponents/SearchSuggestion/SearchSuggestion";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  fetchQuestionSets,
  fetchQuestionSetsCount,
  questionsForQSet,
  userList,
  deleteQSet,
} from "../../../api/questions";
import { fetchQuestionType } from "../../../api/qbMasterData";
import { boardList } from "../../../api/boards";
import {
  fetchCourse,
  fetchSubject,
  fetchSubSubject,
  fetchTopic,
} from "../../../api/institution";

var institution_id = 0;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var sub_subject_id = -1;
// var topic_id = -1;
var link = [];
var linkFinal;
var QSetIdDelete;
var selQSetId;

function QSetView() {
  const [formData, setFormData] = useState({
    QSetName: "",
    createdBy: "",
    QSetCode: "",
    question_type_id: "",
    board_id: "",
    course_id: "",
    subject_id: "",
    sub_subject_id: "",
    topic_id: "",
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
    achieverNameList: [],
  });
  const [startUpLoad, setStartUpLoad] = useState(true);
  const [questionSet, setQuestionSet] = useState([]);
  const [selQSet, setSelQSet] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfQuestionSet, setNumberOfQuestionSet] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [achieverSearch, setAchieverSearch] = useState("");
  const [achieverSelected, setAchieverSelected] = useState("");
  const [searchSuggestion, setSearchSuggestion] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const initialRender = useRef(true);
  const userId = window.localStorage.getItem("userId");

  console.log("formData", formData);
  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    console.log(valueSele, selLabel);
    if (selLabel === "createdBy") {
      setAchieverSelected(valueSele);
      setAchieverSearch(valueSele);
      if (valueSele === "") {
        setFormData((prevState) => {
          return { ...prevState, createdBy: "" };
        });
      }
      // setFormData({ ...formData, [selLabel]: valueSele });
    } else {
      setFormData({ ...formData, [selLabel]: valueSele });
      fetchOtherResults(e, selLabel);
    }
  }

  function handleEdit(QSetId) {
    setStartUpLoad(true);
    window.localStorage.setItem("isEdit", true);
    window.localStorage.setItem("QSetCode", QSetId);
    window.location = "/admin/q-set-creator";
    setStartUpLoad(false);
  }

  async function handleDelete(QSetId) {
    setPopUp(true);
    setDeleteRow(true);
    setStartUpLoad(true);
    if (deleteRow === true) {
      try {
        const deleteQueSet = await deleteQSet(QSetId);
        console.log("deleteQueSet.data.data", deleteQueSet.data.data);
        fetchQuestionSet();
        toast.success("Successfully deleted question set");
        setStartUpLoad(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
      setPopUp(false);
      setDeleteRow(false);
    }
    if (deleteRow === true) {
      QSetIdDelete = QSetId;
    }
  }

  function okDelete() {
    // setDeleteNode(true);
    handleDelete(QSetIdDelete);
    // setPopUp(false);
  }

  //function of cancel in popup
  function cancel() {
    setDeleteRow(false);
    setPopUp(false);
  }

  async function fetchOtherResults(e, selLabel) {
    if (selLabel === "question_type_id") {
      setFormData((prevState) => {
        return {
          ...prevState,
          board_id: -1,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      board_id = -1;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      try {
        const getBoard = await boardList();
        setState({
          ...state,
          board: getBoard.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "board_id") {
      setFormData((prevState) => {
        return {
          ...prevState,
          course_id: -1,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      board_id = e.target.value;
      course_id = -1;
      subject_id = -1;
      sub_subject_id = -1;
      try {
        const getCourse = await fetchCourse(institution_id, board_id);
        setState({
          ...state,
          course: getCourse.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log("fetch course error", err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "course_id") {
      setFormData((prevState) => {
        return {
          ...prevState,
          subject_id: -1,
          sub_subject_id: -1,
          topic_id: -1,
        };
      });
      setStartUpLoad(true);
      course_id = e.target.value;
      subject_id = -1;
      sub_subject_id = -1;
      try {
        const getSubject = await fetchSubject(
          institution_id,
          board_id,
          course_id
        );
        setState({
          ...state,
          subject: getSubject.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log("fetch subject error", err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "subject_id") {
      setFormData((prevState) => {
        return { ...prevState, sub_subject_id: -1, topic_id: -1 };
      });
      setStartUpLoad(true);
      subject_id = e.target.value;
      sub_subject_id = -1;
      try {
        const getSubSubject = await fetchSubSubject(
          institution_id,
          board_id,
          course_id,
          subject_id
        );
        // console.log("getSubSubject", getSubSubject);
        setState((prevState) => ({
          ...prevState,
          subSubject: getSubSubject.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log("fetchSubSubject", err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    if (selLabel === "sub_subject_id") {
      setFormData((prevState) => {
        return { ...prevState, topic_id: -1 };
      });
      setStartUpLoad(true);
      sub_subject_id = e.target.value;
      try {
        const getTopic = await fetchTopic(
          institution_id,
          board_id,
          course_id,
          subject_id,
          sub_subject_id
        );
        setState((prevState) => ({
          ...prevState,
          topic: getTopic.data.data,
        }));
        setStartUpLoad(false);
      } catch (err) {
        console.log(err, "error while fetching data");
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
  }

  useEffect(() => {
    setStartUpLoad(true);
    async function fetchData() {
      try {
        const getQuestionType = await fetchQuestionType();
        setState({
          ...state,
          questionsType: getQuestionType.data.data,
          // accessLevel: getAccessLevel.data.data,
          // medium: getMediums.data.data,
        });
        setStartUpLoad(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        } else {
          toast.error("Server Error");
        }
        setStartUpLoad(false);
      }
    }
    fetchData();
    setFormData((prevState) => {
      return { ...prevState, added_by_user_id: userId };
    });
    // eslint-disable-next-line
  }, []);

  async function fetchQuestionSet() {
    setSubmitLoading(true);
    setNoData(false);
    link = [];
    console.log(board_id, course_id, subject_id);
    if (formData.board_id) {
      link.push(`board_id=${formData.board_id}`);
    }
    if (formData.course_id) {
      link.push(`course_id=${formData.course_id}`);
    }
    if (formData.subject_id) {
      link.push(`subject_id=${formData.subject_id}`);
    }
    if (formData.topic_id) {
      link.push(`topic_id=${formData.topic_id}`);
    }
    if (formData.createdBy !== "") {
      link.push(`added_by_user_id=${formData.createdBy}`);
    }
    if (formData.QSetName !== "") {
      link.push(`display_name=${formData.QSetName}`);
    }
    if (formData.QSetCode !== "") {
      link.push(`question_set_id=${formData.QSetCode}`);
    }
    linkFinal = link.join("&");
    console.log(linkFinal);
    try {
      const getQuestionSetCount = await fetchQuestionSetsCount(linkFinal);
      console.log(
        "getQuestionSetCount",
        getQuestionSetCount.data.data.question_set_count
      );
      setNumberOfQuestionSet(getQuestionSetCount.data.data.question_set_count);
      if (getQuestionSetCount.data.data.question_set_count !== 0) {
        const getQuestionSet = await fetchQuestionSets(linkFinal, 0);
        console.log("getQuestionSet", getQuestionSet.data.data);
        setQuestionSet(getQuestionSet.data.data);
        setSubmitLoading(false);
        window.scrollTo(0, 500);
      } else {
        setNoData(true);
        setSubmitLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log("getQuestionSet err", error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setSubmitLoading(false);
    }
  }

  async function handleSetClick(selectedQuestionSet) {
    console.log("selectedQuestionSet", selectedQuestionSet);
    selQSetId = selectedQuestionSet;
    setStartUpLoad(true);
    try {
      const getSelQuestions = await questionsForQSet(selectedQuestionSet);
      console.log("questionsForQSet", getSelQuestions.data.data);
      setSelQSet(getSelQuestions.data.data);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log("questionsForQSet", error.response.data.message);
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQuestionSet / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  useEffect(() => {
    if (initialRender.current === false) {
      async function handlePagination() {
        console.log(offSet);
        try {
          const getQuestionList = await fetchQuestionSets(linkFinal, offSet);
          console.log("fetchQuestionSets", getQuestionList.data.data);
          setQuestionSet(getQuestionList.data.data);
          setStartUpLoad(false);
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  useEffect(() => {
    console.log("initialRender", initialRender);
    if (initialRender.current === true) {
      initialRender.current = false;
    } else {
      async function searchItemList() {
        setStartUpLoad(true);
        try {
          const searchItems = await userList(achieverSearch);
          console.log("searchItems", searchItems.data.data);
          let searchItemSort = searchItems.data.data.sort(function (a, b) {
            var x = a.user_name.toLowerCase();
            var y = b.user_name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
          setState({
            ...state,
            achieverNameList: searchItemSort,
          });
          setSearchSuggestion(true);
          setStartUpLoad(false);
        } catch (error) {
          if (error.response && error.response.data) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          } else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
        }
      }
      searchItemList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achieverSearch]);

  function handleAchieverClick(object) {
    setAchieverSelected(object.user_name);
    setSearchSuggestion(false);
    setFormData({ ...formData, createdBy: object.user_id });
    // setAchieverCodeSelected(object.achiever_code);
  }

  function handleUlClose() {
    setSearchSuggestion(false);
    setAchieverSelected("");
    setAchieverSearch("");
    setFormData((prevState) => {
      return { ...prevState, createdBy: "" };
    });
    // setAchieverCodeSelected("");
  }

  async function handleOnFocus() {
    setStartUpLoad(true);
    try {
      const searchItems = await userList(achieverSearch);
      console.log("handleOnFocus searchItems", searchItems.data.data);
      let searchItemSortOne = searchItems.data.data.sort(function (a, b) {
        var x = a.user_name.toLowerCase();
        var y = b.user_name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setState({
        ...state,
        achieverNameList: searchItemSortOne,
      });
      setSearchSuggestion(true);
      setStartUpLoad(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(
          "handleOnFocus searchItems error",
          error.response.data.message
        );
        toast.error(error.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }

  return (
    <div>
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <form>
        <div className="queContainer">
          <h1 className="viewH3">
            <ArrowForwardIosIcon className="viewH3Arrow" />
            QSet View
          </h1>
        </div>
        <div className="searchQuestionContainer">
          <h4 className="searchQuestionHeading">Search QSet</h4>
          <div className="viewdropdownDiv">
            <label className="searchQuestionLabel">QSet Name</label>
            <input
              className="searchQuestionSelect"
              onChange={(e) => handleChange(e, "QSetName")}
            ></input>
          </div>
          <div className="questionViewdropdownDiv">
            <label className="searchQuestionLabel">Created By</label>
            <SearchSuggestion
              achieverSelected={achieverSelected}
              // disabled={editValue}
              handleChange={handleChange}
              searchSuggestion={searchSuggestion}
              dropDownData={state.achieverNameList}
              handleUlClose={handleUlClose}
              handleUlClick={handleAchieverClick}
              handleOnFocus={handleOnFocus}
            />
          </div>
          <div className="viewdropdownDiv">
            <label className="searchQuestionLabel">QSet Code</label>
            <input
              className="searchQuestionSelect"
              placeholder="QSet code"
              onChange={(e) => handleChange(e, "QSetCode")}
            ></input>
          </div>
          <Dropdown
            label="Questions Type"
            options={state.questionsType}
            displayName="display_name"
            name="question_type_id"
            value={formData.question_type_id}
            idName="id"
            onChange={handleChange}
            req={false}
          >
            {" "}
          </Dropdown>
          <Dropdown
            label="Board"
            options={state.board}
            name="board_id"
            displayName="board_display_name"
            value={formData.board_id}
            idName="board_id"
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Course"
            options={state.course}
            name="course_id"
            displayName="display_name"
            idName="course_id"
            value={formData.course_id}
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Subject"
            options={state.subject}
            name="subject_id"
            displayName="display_name"
            idName="subject_id"
            value={formData.subject_id}
            onChange={handleChange}
            req={false}
          />
          <Dropdown
            label="Sub-Subject"
            options={state.subSubject}
            name="sub_subject_id"
            displayName="display_name"
            idName="sub_subject_id"
            value={formData.sub_subject_id}
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
          <Dropdown
            label="Topic"
            options={state.topic}
            name="topic_id"
            displayName="display_name"
            idName="topic_id"
            value={formData.topic_id}
            onChange={handleChange}
            req={false}
            displayEngName={"display_name_eng"}
          />
        </div>
        <div className="viewContainer">
          <button
            className="viewSearch"
            onClick={fetchQuestionSet}
            type="button"
          >
            Search
          </button>
          {submitLoading ? (
            <div className="loadingDiv">
              <ReactLoading
                type="spinningBubbles"
                color="#68dff0"
                className="reactLoadingIcon"
              />
              <h5 className="loadingText">Loading</h5>
            </div>
          ) : null}
          {noData ? (
            <div className="loadingDiv">
              <h5 className="loadingTextData">Data is not available</h5>
            </div>
          ) : null}
        </div>
      </form>
      {questionSet.length > 0 && (
        <div className="compulsoryContainerViewQuestions">
          <h4 className="compulsoryHeading">QSet Questions</h4>
          <div className="viewQuestionsSearchDisplay">
            <div className="QSetTablePagination">
              <div className="QSetViewTableContainer">
                {/* <div> */}
                <table className="qSetViewTable">
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Set Code</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Question Count</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionSet.map((row, questionSetKey) => {
                      return (
                        <tr
                          key={questionSetKey}
                          className={
                            +selQSetId === +row.question_set_id
                              ? "selQSet"
                              : null
                          }
                          onClick={(e) => handleSetClick(row.question_set_id)}
                        >
                          <td>{questionSetKey + 1}</td>
                          <td>{row.question_set_id}</td>
                          <td>{row.question_set_name}</td>
                          <td>{row.description}</td>
                          <th>{row.question_count}</th>
                          <td>
                            <button
                              className="editQSet"
                              onClick={(event) =>
                                handleEdit(row.question_set_id)
                              }
                              type="button"
                            >
                              Edit
                            </button>
                            <button
                              className="deleteQSet"
                              onClick={(event) =>
                                handleDelete(row.question_set_id)
                              }
                              type="button"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* </div> */}
              </div>
              <Pagination
                paginationClick={paginationClick}
                numberOfRows={numberOfQuestionSet}
                offSet={offSet}
                pageNumber={pageNumber}
              />
            </div>
            {selQSet.length > 0 && (
              <div>
                <TopicTable checkedRows={selQSet} />
                <br />
                <SubTopicTable checkedRows={selQSet} />
                <br />
                <DifficultyLevel checkedRows={selQSet} />
                <br />
                <Objective checkedRows={selQSet} />
                <br />
                <Marks checkedRows={selQSet} />
                <br />
                <h4 className="compulsoryHeading">Questions</h4>
                <br />
                <div className="qSetViewQuestiondiv">
                  {selQSet.map((row, questionKey) => {
                    return (
                      <div key={questionKey}>
                        <div
                          className="viewResultRowDiv"
                          // onClick={(e) => handleClick(row)}
                        >
                          <div className="qsetNumberDiv">
                            <p>{questionKey + 1}</p>
                          </div>
                          <div className="qsetShortDiv">
                            <p className="viewShortP">
                              {row.short_description}
                            </p>
                            <div className="viewByLastDiv">
                              {row.access_level === "Global" && (
                                <PublicIcon
                                  className="viewPublicIcon"
                                  fontSize="small"
                                />
                              )}
                              {row.access_level === "Organisation" && (
                                <AccountBalanceIcon
                                  className="viewPublicIcon"
                                  fontSize="small"
                                />
                              )}
                              {row.access_level === "Private" && (
                                <PersonIcon
                                  className="viewPublicIcon"
                                  fontSize="small"
                                />
                              )}
                              <p className="viewBy">By: {row.created_by}</p>
                              <p className="viewLastUpdated">
                                Last Updated: {row.last_updated}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div onClick={cancel} className={popUp ? "popUpBack" : "noPopUp"}>
        <div className={popUp ? "yesPopUp" : "noPopUp"}>
          <button onClick={cancel} className="popUpCloseBtn">
            <CloseOutlinedIcon className="closeIcon" />
          </button>
          <div className="warningDiv">
            <ReportOutlinedIcon className="warningIcon" />
          </div>
          <h2 className="popUph2">Are You Sure?</h2>
          <div className="popUpButtonDiv">
            <button onClick={cancel} className="popUpCancelButton">
              Cancel
            </button>
            <button onClick={okDelete} className="popUpOkButton">
              OK
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default QSetView;
