import React from "react";
import "./tableSubTopicRep.css"

function TableSubTopicReport({ tableData }) {
  return (
    <div>
      <table>
        <thead>
          <tr>
          <th>Sl. No.</th>
          <th className="subTopicReportThCourse">Course</th>
          <th className="subTopicReportThSubject">Subject</th>
          <th>Sub-Subject</th>
          <th className="subTopicReportThTopic">Topic</th>
          <th className="subTopicReportThTopic">Sub-Topic</th>
          <th className="subTopicReportThObjective">Objective</th>
          <th>No. Of Questions</th>
          {Array.apply(null, { length: 10 }).map((e, keyDiff) => {
            return <th key={keyDiff}>D. Level {keyDiff + 1}</th>;
          })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, tableKey) => {
            return (
              <tr key={tableKey}>
                <td>{tableKey + 1}</td>
                <td>{row.course_name}</td>
                <td>{row.subject_name}</td>
                <td>{row.sub_subject_name}</td>
                <td>{row.topic_name}</td>
                <td>{row.sub_topic_name}</td>
                <td>{row.objective}</td>
                <td>{row.no_of_questions}</td>
                {Array.apply(null, { length: 10 }).map((e, bodyDiff) => {
                  return (
                    <td key={bodyDiff}>
                      {row[`difficulty_level_${bodyDiff + 1}`] === 0 ||
                      row[`difficulty_level_${bodyDiff + 1}`] === "0"
                        ? "-"
                        : row[`difficulty_level_${bodyDiff + 1}`]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableSubTopicReport;
