// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.startLoadSpinSubTopicRep {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150px !important;
  margin-left: -100px;
  height: 50px;
  margin-top: -25px;
  z-index: 100;
}
.loadingBackgroundSubTopicRep {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 99;
}

.subTopicReportTableContainer {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.download-report-btn {
  color: #fff;
  background-color: #68dff0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  padding: 6px 12px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #48bcb4;
  border-radius: 4px;
  cursor: pointer;
  width: 90px;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/SubTopicReport/subTopicReport.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,eAAe,EAAE,yBAAyB;EAC1C,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,0CAA0C;EAC1C,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;EACd,+BAA+B;EAC/B,eAAe;EACf,mCAAmC;EACnC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,4CAA4C;EAC5C,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".startLoadSpinSubTopicRep {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  width: 150px !important;\n  margin-left: -100px;\n  height: 50px;\n  margin-top: -25px;\n  z-index: 100;\n}\n.loadingBackgroundSubTopicRep {\n  position: fixed; /* Positioning and size */\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(128, 128, 128, 0.5);\n  z-index: 99;\n}\n\n.subTopicReportTableContainer {\n  background: #ffffff;\n  padding: 15px;\n  margin-bottom: 15px;\n  box-sizing: border-box;\n  color: #797979;\n  font-family: \"Ruda\", sans-serif;\n  font-size: 13px;\n  box-shadow: 3px 3px 3px 3px #aab2bd;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n}\n\n.download-report-btn {\n  color: #fff;\n  background-color: #68dff0;\n  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);\n  padding: 6px 12px;\n  margin-bottom: 5px;\n  font-size: 14px;\n  font-weight: normal;\n  line-height: 1.42857143;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: middle;\n  border: 1px solid #48bcb4;\n  border-radius: 4px;\n  cursor: pointer;\n  width: 90px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
