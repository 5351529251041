import { boardList } from "../../../../api/boards";
import { fetchMedium } from "../../../../api/mediums";
import { toast } from "react-toastify";

export async function fetchDataTranslate(setState, state, setStartUpLoad) {
    try {
      const getBoard = await boardList();
      const getMediums = await fetchMedium();
      setState({
        ...state,
        board: getBoard.data.data,
        medium: getMediums.data.data,
      });
      setStartUpLoad(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("Server Error");
      }
      setStartUpLoad(false);
    }
  }