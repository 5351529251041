import React from "react";
import "./dropdown.css";

function Dropdown(props) {
  const displayName = props.displayName;
  const idName = props.idName;
  const displayEngName = props.displayEngName;

  // console.log("props.options props.label", props.label, props.options);

  return (
    <div className="dropdownDiv">
      <label
        className={
          (props.value === -1 || props.value === "-1") && props.notFill === true
            ? props.req
              ? "dropdownLabelRed"
              : "dropdownSelectNotReq"
            : props.req
            ? "dropdownLabel"
            : "dropdownSelectNotReq"
        }
        name={props.label}
      >
        {props.label}
      </label>
      <select
        className="dropdownSelect"
        onChange={(e) => props.onChange(e, props.name)}
        required={props.req}
        value={props.value}
      >
        <option value="-1">Select</option>
        {props.options.map((options, keyDrop) => {
          return displayName === "" ? (
            <option key={keyDrop} value={options[idName]}>
              {options}
            </option>
          ) : (
            <option key={keyDrop} value={options[idName]}>
              {options[displayEngName]
                ? `${options[displayName]} - ${options[displayEngName]}`
                : options[displayName]}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Dropdown;
