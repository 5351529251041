import React, { useState, useEffect, useRef } from "react";
import "./qSetReport.css";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import Pagination from "../../SubComponents/Pagination/Pagination";
import TableQSetReport from "./QSetReportComponents/TableQSetReport/TableQSetReport";
import QSetReportSearch from "./QSetReportComponents/QSetReportSearch/QSetReportSearch";
import { fetchOtherResultsQSetReport } from "./QSetReportFunction/fetchOtherResultsQSetReport";
import { fetchDataQSetReport } from "./QSetReportFunction/fetchDataQSetReport";
import { qSetReportSubmit } from "./QSetReportFunction/qSetReportSubmit";
import { fetchQSetReport } from "../../../api/report";

function QSetReport() {
  const [formData, setFormData] = useState({
    question_type_id: -1,
    board_id: -1,
    course_id: -1,
    subject_id: -1,
    sub_subject_id: -1,
    topic_id: -1,
    medium_id: -1,
    from_date: "",
    to_date: "",
  });
  const [state, setState] = useState({
    questionsType: [],
    medium: [],
    board: [],
    course: [],
    subject: [],
    subSubject: [],
    topic: [],
  });
  const [qSetReportList, setQSetReportList] = useState([]);
  // const [questionView, setQuestionView] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [numberOfQSetReports, setNumberOfQSetReports] = useState(0);
  const [noData, setNoData] = useState(false);
  const initialRender = useRef(true);

  // const userId = window.localStorage.getItem("userId");
  var institution_id = 0;
  const limit = 10;
  console.log(formData);

  function handleChange(e, selLabel) {
    const valueSele = e.target.value;
    fetchOtherResultsQSetReport(
      e,
      selLabel,
      institution_id,
      setFormData,
      setStartUpLoad,
      state,
      setState,
      formData
    );
    setFormData((prevState) => ({
      ...prevState,
      [selLabel]: valueSele,
    }));
  }

  function paginationClick(number, offSetNumber) {
    if (Math.ceil(numberOfQSetReports / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
      setStartUpLoad(true);
    }
  }

  useEffect(() => {
    setStartUpLoad(true);

    fetchDataQSetReport(setState, state, setStartUpLoad);
    // setFormData((prevState) => {
    //   return { ...prevState, added_by_user_id: userId };
    // });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialRender.current === false) {
      async function handlePagination() {
        console.log(offSet);
        qSetReportSubmit(
          setSubmitLoading,
          setNoData,
          formData,
          setNumberOfQSetReports,
          setQSetReportList,
          limit,
          offSet,
          setStartUpLoad
        );
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSet]);

  const createDownloadInfo = (formData) => {
    const link = [];
    if (formData.medium_id && formData.medium_id !== -1) {
      link.push(`medium_id=${formData.medium_id}`);
    }
    if (formData.board_id && formData.board_id !== -1) {
      link.push(`board_id=${formData.board_id}`);
    }
    if (formData.course_id && formData.course_id !== -1) {
      link.push(`course_id=${formData.course_id}`);
    }
    if (formData.subject_id && formData.subject_id !== -1) {
      link.push(`subject_id=${formData.subject_id}`);
    }
    if (formData.sub_subject) {
      link.push(`sub_subject_id=${formData.sub_subject}`);
    }
    if (formData.topic_id && formData.topic_id !== -1) {
      link.push(`topic_id=${formData.topic_id}`);
    }
    if (formData.from_date) {
      link.push(`from_date=${formData.from_date}`);
    }
    if (formData.to_date) {
      link.push(`to_date=${formData.to_date}`);
    }

    const linkFinal = link.join("&");
    return linkFinal;
  };

  const handleDownload = async () => {
    setStartUpLoad(true);
    const excelDownloadParams = createDownloadInfo(formData);
    // console.log("excelDownloadParams", excelDownloadParams);
    fetchQSetReport(excelDownloadParams)
      .then((res) => {
        const excelData = res;
        const fileName =
          excelData?.headers[`content-disposition`].split("=")[1];
        console.log("fileName", fileName);
        saveAs(excelData.data, fileName);
        toast.success("file downloaded");
        setStartUpLoad(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.message);
        setStartUpLoad(false);
      });
  };

  return (
    <div>
      {startUpLoad ? (
        <div>
          <ReactLoading
            type="spin"
            color="#68dff0"
            className="startLoadSpinQSetRep"
          />
        </div>
      ) : null}
      <QSetReportSearch
        state={state}
        formData={formData}
        handleChange={handleChange}
        submitLoading={submitLoading}
        noData={noData}
        setSubmitLoading={setSubmitLoading}
        setNoData={setNoData}
        setNumberOfQSetReports={setNumberOfQSetReports}
        setQSetReportList={setQSetReportList}
        limit={limit}
        offSet={offSet}
        setStartUpLoad={setStartUpLoad}
        createDownloadInfo={createDownloadInfo}
      />
      {qSetReportList.length !== 0 ? (
        <div className="qSetReportTableContainer">
          <button className="download-report-btn" onClick={handleDownload}>
            Download
          </button>

          <div>
            <TableQSetReport tableData={qSetReportList} />
          </div>
          <Pagination
            paginationClick={paginationClick}
            numberOfRows={numberOfQSetReports}
            offSet={offSet}
            pageNumber={pageNumber}
          />
        </div>
      ) : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default QSetReport;
