import { fetchNewDropDown } from "./fetchNewDropDown";


export function populateFormData(setFormData, setStartUpLoad, setState, institution_id) {
  setStartUpLoad(true);
  const oldFormData = JSON.parse(
    window.localStorage.getItem("translateQuestion")
  );
  console.log(oldFormData);
  window.localStorage.removeItem("translateQuestion");
  if (oldFormData === null || oldFormData === undefined) {
    window.location= "/admin/view-questions";
  } else {
    setFormData({...oldFormData, translate: true});
    fetchNewDropDown(setStartUpLoad, oldFormData, setState, institution_id)
  }
}
