import React, { useState } from "react";
import Teacher from "../../../assets/images/Teacher.jpg";
import "./togglebar.css";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ListIcon from "@mui/icons-material/List";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

function Togglebar(props) {
  const [question, setQuestion] = useState(false);
  const [qset, setQset] = useState(false);
  const [report, setReport] = useState(false);

  const role = window.localStorage.getItem("role");

  function questionHandle() {
    setQuestion(!question);
    qset && setQset(!qset);
    report && setReport(!report);
  }

  function qsetHandle() {
    setQset(!qset);
    question && setQuestion(!question);
    report && setReport(!report);
  }

  function reportHandle() {
    setReport(!report);
    question && setQuestion(!question);
    qset && setQset(!qset);
  }

  return (
    <div>
      <img className="toggleImage" src={Teacher} alt="teacher" />
      <h5 className="toggleH5">Teacher</h5>
      <button className="toggleQue" onClick={questionHandle}>
        <LibraryBooksIcon className="bookIcon" /> Questions
      </button>
      {question ? (
        <SlideDown className={"my-dropdown-slidedown"}>
          <div>
            <Link
              to="/admin/backlog"
              className={
                props.path === "/admin/backlog" ? "toggleLink1" : "toggleLink"
              }
            >
              My Backlog
            </Link>
            <br />
            <Link
              to="/admin/upload-questions"
              className={
                props.path === "/admin/upload-questions"
                  ? "toggleLink1"
                  : "toggleLink"
              }
            >
              Upload Questions
            </Link>
            <br />
            <Link
              to="/admin/migrate"
              className={
                props.path === "/admin/migrate" ? "toggleLink1" : "toggleLink"
              }
            >
              Migrate
            </Link>
            <br />
            <Link
              to="/admin/view-questions"
              className={
                props.path === "/admin/view-questions"
                  ? "toggleLink1"
                  : "toggleLink"
              }
            >
              View Questions
            </Link>
            <br />
            {/* <Link
              to="/admin/translate-questions"
              className={
                props.path === "/admin/translate-questions"
                  ? "toggleLink1"
                  : "toggleLink"
              }
            >
              Translate Questions
            </Link> */}
            {/* <br /> */}
            {/* <Link
              to="/admin/migrate-map"
              className={
                props.path === "/admin/migrate-map"
                  ? "toggleLink1"
                  : "toggleLink"
              }
            >
              MIgrate-Map
            </Link> */}
          </div>
        </SlideDown>
      ) : (
        ""
      )}
      <br className="toggleBr" />
      <button className="toggleQSet" onClick={qsetHandle}>
        <ListIcon className="bookIcon" /> QSet
      </button>
      {qset ? (
        <SlideDown className={"my-dropdown-slidedown"}>
          <div>
            <Link
              to="/admin/q-set-creator"
              className={
                props.path === "/admin/q-set-creator"
                  ? "toggleLink1"
                  : "toggleLink"
              }
            >
              QSet Creator
            </Link>
            <br />
            <Link
              to="/admin/q-set-view"
              className={
                props.path === "/admin/q-set-view"
                  ? "toggleLink1"
                  : "toggleLink"
              }
            >
              QSet View
            </Link>
            <br />
            <Link
              to="/admin/q-set-map"
              className={
                props.path === "/admin/q-set-map" ? "toggleLink1" : "toggleLink"
              }
            >
              QSet Map
            </Link>
          </div>
        </SlideDown>
      ) : (
        ""
      )}
      <br className="toggleBr" />
      {role === "Admin" ? (
        <div>
          <button className="toggleReport" onClick={reportHandle}>
            <ListIcon className="bookIcon" /> Report
          </button>
          {report ? (
            <SlideDown className={"my-dropdown-slidedown"}>
              <div>
                <Link
                  to="/admin/topic-report"
                  className={
                    props.path === "/admin/topic-report"
                      ? "toggleLink1"
                      : "toggleLink"
                  }
                >
                  Topic Report
                </Link>
                <br />
                <Link
                  to="/admin/sub-topic-report"
                  className={
                    props.path === "/admin/sub-topic-report"
                      ? "toggleLink1"
                      : "toggleLink"
                  }
                >
                  Sub Topic Report
                </Link>
                <br />
                <Link
                  to="/admin/q-set-report"
                  className={
                    props.path === "/admin/q-set-report"
                      ? "toggleLink1"
                      : "toggleLink"
                  }
                >
                  QSet Report
                </Link>
              </div>
            </SlideDown>
          ) : (
            ""
          )}{" "}
        </div>
       ) : null}
    </div>
  );
}

export default Togglebar;
