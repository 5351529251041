import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

function Navbar() {
  function handleLogout() {
    return window.localStorage.clear();
  }

  return (
    <div>
      <div className="navbar">
        <div className="navbarContainer">
          <div className="navbarSubContainer">
            <Link className="navbarLogo" to="/admin">
              <h1 className="navbarH1">QUESTION BANK</h1>
              <div className="navbarh2">ver 0.1</div>
            </Link>
          </div>
          <Link to="/">
            <button className="navbarLogout" onClick={handleLogout}>
              Logout
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
