// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topicReportTableContainer table{
    border-collapse: collapse;
}

.topicReportTableContainer thead tr {
  height: 40px;
}

.topicReportTableContainer thead th {
  padding: 5px;
  color: #828282;
}

.topicReportTableContainer thead {
  background-color: #f2f2f2;
}

.topicReportThCourse {
  width: 8%;
}

.topicReportThSubject {
  width: 10%;
}

.topicReportThTopic {
  width: 20%;
}

.topicReportTableContainer tbody tr {
  height: 30px;
  border-bottom: 1px solid #e0e0e0;
}

.topicReportTableContainer tbody tr td {
  border-bottom: 1px solid #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/TopicReport/TopicReportComponents/TableTopicReport/tableTopicReport.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".topicReportTableContainer table{\n    border-collapse: collapse;\n}\n\n.topicReportTableContainer thead tr {\n  height: 40px;\n}\n\n.topicReportTableContainer thead th {\n  padding: 5px;\n  color: #828282;\n}\n\n.topicReportTableContainer thead {\n  background-color: #f2f2f2;\n}\n\n.topicReportThCourse {\n  width: 8%;\n}\n\n.topicReportThSubject {\n  width: 10%;\n}\n\n.topicReportThTopic {\n  width: 20%;\n}\n\n.topicReportTableContainer tbody tr {\n  height: 30px;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.topicReportTableContainer tbody tr td {\n  border-bottom: 1px solid #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
