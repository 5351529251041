import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Screens/Login/Login";
import Layout from "./Layout/Layout";
import ForgotPassword from "./Screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./Screens/ResetPassword/ResetPassword";

function App() {
  return (
    <div>
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/admin" component={Layout} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/:user_id/new-password" component={ResetPassword} />
            <Redirect to="/" />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
