export const BASE_URL =
  process.env.REACT_APP_REWRITE_URLS === "1"
    ? process.env.REACT_APP_REWRITE_BASE_URL
    : process.env.REACT_APP_BASE_URL;
export const BASE_URL_MIGRATE =
  process.env.REACT_APP_REWRITE_URLS === "1"
    ? process.env.REACT_APP_REWRITE_BASE_URL_MIGRATE
    : process.env.REACT_APP_BASE_URL_MIGRATE;
export const REWRITE_MIGRATE_PATH = process.env.REACT_APP_REWRITE_MIGRATE_PATH;
export const REWRITE_URLS = process.env.REACT_APP_REWRITE_URLS;
