import React from "react";
import "./tableQSetReport.css"

function TableQSetReport({ tableData }) {
  return (
    <div>
      <table>
        <thead>
          <tr>
          <th>Sl. No.</th>
          <th className="qSetReportThCourse">Course</th>
          <th className="qSetReportThCourse">Medium</th>
          <th className="qSetReportThSubject">Subject</th>
          <th className="qSetReportThSubject">Sub-Subject</th>
          <th className="qSetReportThTopic">Topic</th>
          <th>No. Of QSet</th>
          <th>Question Count</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, tableKey) => {
            return (
              <tr key={tableKey}>
                <td>{tableKey + 1}</td>
                <td>{row.course_name}</td>
                <td>{row.medium}</td>
                <td>{row.subject_name}</td>
                <td>{row.sub_subject_name}</td>
                <td>{row.topic_name}</td>
                <td>{row.no_of_qset}</td>
                <td>{row.question_count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableQSetReport;
