import React from "react";
import "./dropdownQSet.css";

function DropdownQSet(props) {
  const displayName = props.displayName;
  const idName = props.idName;

  return (
    <div className="dropdownQSetDiv">
      <label
        className={
          (props.value === -1 || props.value === "-1") && props.notFill === true
            ? props.req
              ? "dropdownQSetLabelRed"
              : "dropdownQSetSelectNotReq"
            : props.req
            ? "dropdownQSetLabel"
            : "dropdownQSetSelectNotReq"
        }
        name={props.label}
      >
        {props.label}
      </label>
      <select
        className="dropdownQSetSelect"
        onChange={(e) => props.onChange(e, props.name)}
        required={props.req}
        value={props.value}
      >
        <option value="-1">Select</option>
        {props.options.map((options, keyDrop) => {
          return displayName === "" ? (
            <option key={keyDrop} value={options[idName]}>
              {options}
            </option>
          ) : (
            <option key={keyDrop} value={options[idName]}>
              {options[displayName]}
            </option>
          );
        })}
      </select>
    </div>
  );
}


export default DropdownQSet;
