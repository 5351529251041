import https from "../init/https";

//QB-QUEST-01: Upload Question Group Files
export async function uploadQuestionFunction(question_format_id, userId, data) {
  console.log("incoming question data", data);
  const uploadQuestionData = await https.post(
    `/qb/question_group_files?question_format_id=${question_format_id}&user_id=${userId}`,
    data
  );
  console.log("uploadQuestionData", uploadQuestionData);
  return uploadQuestionData;
}

// QB-QUEST-02: Delete Uploaded Question Files
export async function deleteQuestionFunction(selQuestion) {
  const deleteQuestionData = await https.delete(
    `/qb/question_files/${selQuestion}`
  );
  return deleteQuestionData;
}

//QB-QUEST-03: List the backlog question files
export async function getBacklogFunction(userId, offSet) {
  const getBacklogData = await https.get(
    `/qb/question_files/backlog/${userId}?limit=10&offset=${offSet}`
  );
  return getBacklogData;
}

// QB-QUEST-03-01: Get count of backlog question files
export async function getBacklogCountFunction(userId) {
  const getBacklogCountData = await https.get(
    `/qb/question_files/backlog_count/${userId}`
  );
  return getBacklogCountData;
}

// QB-QUEST-04: Create a new Question
export async function createQuestion(formData) {
  const createQuestionData = await https.post(`/qb/questions`, formData);
  return createQuestionData;
}

// QB-QUEST-05: Display Question Search Results
export async function fetchQuestions(linkFinal, offSet) {
  const fetchQuestionsData = await https.get(
    `/qb/questions/search?${linkFinal}&limit=10&offset=${offSet}`
  );
  return fetchQuestionsData;
}

// QB-QUEST-05-01: Get count of results for QB-QUEST-05
export async function fetchQuestionsCount(linkFinal) {
  const fetchQuestionsCountData = await https.get(
    `/qb/questions/count_questions?${linkFinal}`
  );
  return fetchQuestionsCountData;
}

// QB-QUEST-06: Edit a Question
export async function editQuestion(question_id, formData) {
  console.log("question_id, formData in editQuestion", question_id, formData)
  const editQuestionData = await https.put(
    `/qb/questions/${question_id}`,
    formData
  );
  console.log("editQuestionData", editQuestionData)
  return editQuestionData;
}

//QB-QUEST-07: Fetch the details of a particuar question
export async function fetchParticularQuestion(question_id) {
  const fetchParticularQuestionData = await https.get(
    `/qb/questions/${question_id}`
  );
  console.log("fetchParticularQuestionData", fetchParticularQuestionData)
  return fetchParticularQuestionData;
}

//QB-QUEST-08: Add a new Question Set
export async function createQuestionSet(setData) {
  const createQuestionSetData = await https.post(`/qb/question_sets`, setData);
  return createQuestionSetData;
}

//QB-QUEST-09: Search for question sets based on input criteria
export async function fetchQuestionSets(linkFinal, offSet) {
  const fetchQuestionSetsData = await https.get(
    `/qb/question_sets/search?${linkFinal}&limit=10&offset=${offSet}`
  );
  return fetchQuestionSetsData;
}

//QB-QUEST-09-01: Get count of results for QB-QUEST-09
export async function fetchQuestionSetsCount(linkFinal) {
  const fetchQuestionSetsCountData = await https.get(
    `/qb/question_sets/count_question_sets?${linkFinal}`
  );
  return fetchQuestionSetsCountData;
}

//QB-QUEST-10: Delete questions from a question set
export async function deleteQuestionFromSet(questionSetIdEdit, queId) {
  const deleteQuestionFromSetData = await https.delete(
    `/qb/question_set_questions/${questionSetIdEdit}/${queId}`
  );
  return deleteQuestionFromSetData;
}

//QB-QUEST-11: Get questions for given question set
export async function questionsForQSet(SelQueSet) {
  const questionsForQSetData = await https.get(
    `/qb/question_sets_questions/${SelQueSet}`
  );
  return questionsForQSetData;
}

//QB-QUEST-12: Update the Question set with Metadata
export async function updateSetMetadata(setCode, metadata) {
  const updateSetMetadataData = await https.put(
    `/qb/question_sets/${setCode}`,
    metadata
  );
  return updateSetMetadataData;
}

//QB-QUEST-13: List all the users based on the search text
export async function userList(achieverSearch) {
  const userListData = await https.get(
    `/qb/search/users?search_text=${achieverSearch}`
  );
  return userListData;
}

//QB-QUEST-14: Delete a Question Set
export async function deleteQSet(QSetId) {
  const deleteQSetData = await https.delete(`/qb/question_sets/${QSetId}`);
  return deleteQSetData;
}

//QB-QUEST-15: Curate individual questions
export async function curateQuestion(question_id, curatorData) {
  const curateQuestionData = await https.put(
    `/qb/questions/${question_id}/curate`,
    curatorData
  );
  return curateQuestionData;
}

//QB-QUEST-16: Reject a question by Curator
export async function rejectQuestion(question_id, data) {
  const rejectQuestionData = await https.put(
    `/qb/questions/${question_id}/reject`,
    data
  );
  return rejectQuestionData;
}

//QB-QUEST-17: Delete Uploaded Question by Admin
export async function deleteQuestionAdmin(questionId, userId) {
  const deleteQueAdmin = await https.delete(
    `/qb/questions/${questionId}/delete?user_id=${userId}`
  );
  return deleteQueAdmin;
}

//QB-QUEST-18: List all the Curators
export async function curatorList(text) {
  const curatorListData = await https.get(
    `qb/users/curators?search_text=${text}`
  );
  return curatorListData;
}
