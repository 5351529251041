import https from "../init/https";

//QB-USER-01: Create a User Preference Record for Question Metadata
export async function questionMetadata(userId, pref){
    const questionMetadataData = await https.post(`/users/${userId}/preferences/question_metadata`, pref);
    return questionMetadataData; 
}

//QB-USER-02: Update the User Preference Record for Question Metadata
export async function updateQueMetadata(userId, pref){
    const updateQueMetadataData = await https.put(`/users/${userId}/preferences/question_metadata`, pref);
    return updateQueMetadataData; 
}

//QB-USER-03: Retrieve the User Preference Record for Question Metadata
export async function getQueMetadata(userId, defaults){
    const getQueMetadataData = await https.get(`/users/${userId}/preferences/question_metadata?pref_key=${defaults}`);
    return getQueMetadataData; 
}