import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { BASE_URL } from "../../config/Api";
// import Axios from "axios";
import { passwordSet } from "../../../api/login";
import ReactLoading from "react-loading";

const initialValues = {
  passwordOne: "",
  passwordTwo: "",
};
const validationSchema = Yup.object({
  passwordOne: Yup.string().required("Password is required"),
  passwordTwo: Yup.string().required("Password is required"),
});

function ResetPassword(props) {
  const [login, setLogin] = useState(false);
  const [notEquall, setNotEquall] = useState(false);
  const [startUpLoad, setStartUpLoad] = useState(false);

  let user_id = props.match.params.user_id;

  console.log(props.match.params.user_id);

  const onSubmit = async (values) => {
    if (values.passwordOne === values.passwordTwo) {
      setStartUpLoad(true);
      setNotEquall(false);
      var data = {
          user_id: user_id,
          password: values.passwordOne,
        }
      // await Axios.post(`${BASE_URL}/user/save`, {
      //   user_id: user_id,
      //   password: values.passwordOne,
      // })
      await passwordSet(data)
        .then((response) => {
          console.log(response);
          toast.success("New Password Created Successfully");
          setStartUpLoad(false);
          setTimeout(() => {
            // return (window.location = "/");
            setLogin(true);
          }, 3000);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          }else {
            toast.error("Server Error");
          }
          setStartUpLoad(false);
          setTimeout(() => {
            return (window.location = "/");
          }, 3000);
        });
    }
    if (values.passwordOne !== values.passwordTwo) {
      setNotEquall(true);
      toast.error("Password Must Match");
    }
  };

  console.log(login);
  if (login) {
    return <Redirect from="/" to="/" />;
  }

  return (
    <div className="loginContainer">
      {startUpLoad ? (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#68dff0" className="startLoadSpin" />
        </div>
      ) : null}
      <div>
        <h1 className="loginH1">Reset Password</h1>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <div>
                <Form>
                  <Box component="div">
                    <Field
                      type="password"
                      id="passwordOne"
                      name="passwordOne"
                      placeholder="Password"
                      className="loginUsername"
                    />
                  </Box>
                  <ErrorMessage name="passwordOne" component="div">
                    {(error) => <div className="error">{error}</div>}
                  </ErrorMessage>
                  <Box>
                    <Field
                      type="password"
                      id="passwordTwo"
                      name="passwordTwo"
                      placeholder="Re-enter Password"
                      className="loginUsername"
                    />
                  </Box>
                  <ErrorMessage name="passwordTwo" component="div">
                    {(error) => <div className="error">{error}</div>}
                  </ErrorMessage>
                  {notEquall ? (
                    <p className="notMatching">Password not Matching</p>
                  ) : (
                    ""
                  )}
                  <button type="submit" className="loginButton">
                    <div>Submit</div>
                  </button>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ResetPassword;
