// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compulsoryContainerMap {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #797979;
  font-family: "Ruda", sans-serif;
  font-size: 13px;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Screens/MigrateMap/migrateMap.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;EACd,+BAA+B;EAC/B,eAAe;EACf,mCAAmC;EACnC,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".compulsoryContainerMap {\n  background: #ffffff;\n  padding: 15px;\n  margin-bottom: 15px;\n  box-sizing: border-box;\n  color: #797979;\n  font-family: \"Ruda\", sans-serif;\n  font-size: 13px;\n  box-shadow: 3px 3px 3px 3px #aab2bd;\n  margin-left: 15%;\n  margin-right: 15%;\n  margin-top: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
