import httpsMsSql from "../init/httpsMsSql";

//QB-MSSQL-01: Get Language List
export async function fetchMiLan(){
    const fetchMiLanData = await httpsMsSql.get(`/GetLanguageList`);
    return fetchMiLanData;
}

//QB-MSSQL-02: Get Syllabus List
export async function fetchMiSy(){
    const fetchMiSyData = await httpsMsSql.get(`/GetSyllabusList`);
    return fetchMiSyData;
}

//QB-MSSQL-03: Get Class List
export async function fetchMiClass(language_id, syllabus_id){
    const fetchMiClassData = await httpsMsSql.get(`/GetClassList?languageID=${language_id}&SyllabusID=${syllabus_id}`);
    return fetchMiClassData;
}

//QB-MSSQL-04: Get Subject List
export async function fetchMiSubject(language_id, syllabus_id, class_id){
    const fetchMiSubjectData = await httpsMsSql.get(`/GetSubjectList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}`);
    return fetchMiSubjectData;
}

//QB-MSSQL-05: Sub-subject list
export async function fetchMiSubSubject(subject_id){
    const fetchMiSubSubjectData = await httpsMsSql.get(`/GetSubSubjectList?SubjectID=${subject_id}`);
    return fetchMiSubSubjectData;
}

//QB-MSSQL-06: Get Topic List
export async function fetchMiTopic(language_id, syllabus_id, class_id, subject_id, sub_subject_id){
    const fetchMiTopicData = await httpsMsSql.get(`/GetTopicList?languageID=${language_id}&SyllabusID=${syllabus_id}&ClassID=${class_id}&SubjectID=${subject_id}&SubSubjectID=${sub_subject_id}`);
    return fetchMiTopicData; 
}

//QB-MSSQL-07: Get Sub-Topic List
export async function fetchMiSUbTopic(topic_id){
    const fetchMiSubTopicData = await httpsMsSql.get(`/GetSubTopicList?TopicID=${topic_id}`);
    return fetchMiSubTopicData;
}

//QB-MSSQL-08: Total No of Question Count
export async function fetchMiQuesCount(link){
    const fetchMiQuesCountData = await httpsMsSql.get(`/GetQuestionTotalCount?${link}`);
    return fetchMiQuesCountData;
}

//QB-MSSQL-09: Question List
export async function fetchMiQues(link, offSet){
    const fetchMiQuesData = await httpsMsSql.get(`/GetQuestionAllList?${link}&OFFSET=${offSet}&FETCHNEXT=${10}`);
    return fetchMiQuesData;
}