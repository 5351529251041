import https from "../init/https";

// QB-MAST-01: Retrieve Access Levels Master Data
export async function fetchAccessLevel(){
    const fetchAccessLevelData = await https.get(`/qb/access_levels`);
    return fetchAccessLevelData;
}

//QB-MAST-02: Retrieve Response Type Master Data
export async function fetchResponseType(){
    const fetchResponseTypeData = await https.get(`/qb/response_types`);
    return fetchResponseTypeData;
}

//QB-MAST-03: Retrieve Evaluation Type Master Data
export async function fetchEvalType(){
    const fetchEvalTypeData = await https.get(`/qb/evaluation_types`);
    return fetchEvalTypeData;
}

//QB-MAST-04: Retrieve Question Type Master Data
export async function fetchQuestionType(){
    const fetchQuestionTypeData = await https.get(`/qb/question_types`);
    return fetchQuestionTypeData; 
}

//QB-MAST-05: Retrieve Question Format Master Data
export async function fetchQueFormat(){
    const fetchQueFormatData = await https.get(`/qb/question_formats`);
    return fetchQueFormatData;
}

//QB-MAST-06: Retrieve Answer Input Type Master Data
export async function fetchAnsType(){
    const fetchAnsTypeData = await https.get(`/qb/answer_input_types`);
    return fetchAnsTypeData;
}