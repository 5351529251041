// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summaryTable {
  display: inline-block;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 96%;
}

.summaryTable,
.summaryTable th,
.summaryTable td {
  border: 1px solid #797979;
  border-collapse: collapse;
  font-weight: 500;
}

.summaryTable th,
.summaryTable td {
  padding: 5px;
  text-align: center;
}

.summaryTable th:nth-child(1),
.summaryTable td:nth-child(1) {
  font-weight: 800;
}


.summaryTableVertical {
  display: inline-block;
  overflow: auto;
  white-space: nowrap;
  max-width: 96%;
  max-height: 400px;
  /* overflow-y: auto; */
}

.summaryTableVertical,
.summaryTableVertical th,
.summaryTableVertical td {
  border: 1px solid #797979;
  border-collapse: collapse;
  /* font-weight: 500; */
}

.summaryTableVertical th,
.summaryTableVertical td {
  padding: 5px;
  text-align: center;
}

.summaryTabletextAlign{
  text-align: left !important;
}

/* .summaryTableVertical th:nth-child(1),
.summaryTableVertical td:nth-child(1) {
  font-weight: 800;
} */`, "",{"version":3,"sources":["webpack://./src/components/SubComponents/SummaryTable/summaryTable.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;;;EAGE,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;AAClB;;;AAGA;EACE,qBAAqB;EACrB,cAAc;EACd,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;;;EAGE,yBAAyB;EACzB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;;GAGG","sourcesContent":[".summaryTable {\n  display: inline-block;\n  overflow-x: auto;\n  white-space: nowrap;\n  max-width: 96%;\n}\n\n.summaryTable,\n.summaryTable th,\n.summaryTable td {\n  border: 1px solid #797979;\n  border-collapse: collapse;\n  font-weight: 500;\n}\n\n.summaryTable th,\n.summaryTable td {\n  padding: 5px;\n  text-align: center;\n}\n\n.summaryTable th:nth-child(1),\n.summaryTable td:nth-child(1) {\n  font-weight: 800;\n}\n\n\n.summaryTableVertical {\n  display: inline-block;\n  overflow: auto;\n  white-space: nowrap;\n  max-width: 96%;\n  max-height: 400px;\n  /* overflow-y: auto; */\n}\n\n.summaryTableVertical,\n.summaryTableVertical th,\n.summaryTableVertical td {\n  border: 1px solid #797979;\n  border-collapse: collapse;\n  /* font-weight: 500; */\n}\n\n.summaryTableVertical th,\n.summaryTableVertical td {\n  padding: 5px;\n  text-align: center;\n}\n\n.summaryTabletextAlign{\n  text-align: left !important;\n}\n\n/* .summaryTableVertical th:nth-child(1),\n.summaryTableVertical td:nth-child(1) {\n  font-weight: 800;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
